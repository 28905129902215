<template>
  <div id="sobre" class="home-features container-bg-dark">
    <div class="home-right-section2">
      <div class="home-card">
        <!-- <img
          alt="image"
          src="https://picsum.photos/id/221/600/500"
          class="home-image2-v2"
        /> -->
        <img
            alt="Morpheus"
            src="images/ANTONIO PLASTICA-329.jpg"
            class="img-especialidades"
            height="583"
            width="auto"
          />
      </div>
      <div class="home-card">
        <div class="home-heading-container1">
          <!-- <h4 class="home-pre-heading-v3 text-light-small-extra-v6">
            QUEM É DR. ANTÔNIO TEIXEIRA?
          </h4>
          <h4 class="home-heading-v3 Section-Heading-v4 text-dark-small-extra-v6">
            Conheça seu Médico
          </h4> -->
          <h4 class="home-heading-v3 Section-Heading-v4 text-dark-small-extra-v6">
            QUEM É DR. ANTÔNIO TEIXEIRA?
          </h4>
          <h4 class="home-pre-heading-v3 text-light-small-extra-v6">
            GO - CRM: 16.890 | RQE: 8.380
          </h4>
          <h4 class="home-pre-heading-v3 text-light-small-extra-v6">
            TO - CRM: 2.067 | RQE: 
          </h4>
        </div>
        <!-- <span class="home-content-container2">
          Seja bem vinda! Escolha o seu procedimento e agende sua consulta agora. Nossa equipe altamente capacitada está à disposição para esclarecer todas as suas dúvidas.
        </span> -->
        <span class="home-content-container2 text-light-small-extra-v6">
          <!-- esse é o texto certo, só comentei pra arrumar dps -->

          Ele não é apenas um médico, mas alguém que nasceu e criou-se no interior de São Paulo e decidiu
          construir sua vida em Goiânia. É casado, pai de dois filhos, vivendo à sua jornada da vida com a
          mesma paixão e dedicação que aplica em sua profissão. Inicie minha jornada na Faculdade de
          Medicina de Presidente Prudente, SP. Após a graduação de seis anos e após fiz dois anos de residência
          em cirurgia geral no Hospital Universitário da Unoeste. Ao concluir a cirura geral, a FAB me 
          convocou para o Hospital do CTA, em São José dos Campos, onde aprendi muito.
          
          Minha paixão sempre foi a cirurgia plástica, e me especializei no renomado SERVIÇO DE CIRURGIA
          PLÁSTICA DR. EWALDO BOLIVAR DE SOUZA PINTO - UNISANTA, em Santos, com mestres como Dr. 
          Ewaldo Bolivar e Dr. Oswaldo Saldanha por 4 anos.
          
          Hoje, atuo no Hospital Unique, em Goiânia, e na Prime Clínica, em Palmas. Sou membro TITULAR da
          SBCP (Sociedade Brasileira de Cirurgia Plástica), assegurando altos padrões éticos. Minha equipe
          multidisciplinar cuida de cada paciente, da consulta à recuperação pós-operatória. Minha missão 
          vai além das cirurgias, oferecendo conforto, confiança e apoio em cada etapa. Seja bem-vindo à 
          minha história, onde paixão e dedicação transformam vidas.
        </span>

        <div class="">
          <div class="nav-btn">
            <a href="https://api.whatsapp.com/send/?phone=13981911233&amp;text=Ol%C3%A1%2C+gostaria+de+marcar+uma+consulta.">
              Agendar Consulta
            </a>
          </div>
        </div>
      </div>

      <div class="home-card">
          <!-- <img
          alt="image"
          src="https://picsum.photos/id/221/600/500"
          class="home-image2-v2"
        /> -->
          <img
              alt="Morpheus"
              src="images/ANTONIO PLASTICA-329.jpg"
              class="img-especialidades2"
              height="583"
              width="auto"
            />
        </div>
    </div>
    
  </div>
</template>

<script>
export default {
    name: "Sobre",
    data() {
      return {
        rawvpwc: ' ',
        rawm3gm: ' ',
        rawyzqr: ' ',
      }
    },
}
</script>

<style scoped>
  .home-features {
    width: 100%;
    display: flex;
    /* max-width: var(--dl-size-size-maxcontent); */
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
    /* margin-bottom: var(--dl-space-space-fiveunits); */
    background-color:var(--dl-color-gray-1100);
    background-size: cover;
  }

  .home-card {
    width: 48%;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    flex-direction: column;
    justify-content: space-between;
  }

  .home-right-section2 {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    align-items: center;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: row;
    justify-content: space-between;
  }

  .home-heading-container1 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
    justify-content: center;
  }

  .home-card {
    width: 48%;
    display: flex;
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    flex-direction: column;
    justify-content: space-between;
  }

  .home-content-container2 {
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }

  .container-bg-dark {
    background-color:var(--dl-color-gray-900);
    background-size: cover;
  }

  .Section-Heading-v4 {
    font-size: 26px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1.22;
    text-transform: none;
    text-decoration: none;
    text-align: center;
  }

  .home-heading-v3
  .home-pre-heading-v3 {
    width: 100%;
    text-align: center;
    margin-bottom: var(--dl-space-space-fiveunits);
  }

  .img-especialidades {
    width: 100%;
    height: auto;
    border-radius: var(--dl-radius-radius-radius4);
  }

  .nav-btn {
    /* color: var(--dl-color-gray-white); */
    color: var(--dl-color-seguranca);
    border-radius: var(--dl-radius-radius-radius8);
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-unit);
    /* background-color: var(--dl-color-gray-black); */
    background-color: var(--dl-color-responsabilidade);
    /* display: right; */
    
    /* border-color: var(--dl-color-gray-black); */
    /* border-width: 1px; */

    cursor: pointer;

    font-size: 18px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
  }

  .text-light-small-extra-v6 {
    color: var(--dl-color-seriedade);
    /* font-weight: 500; */
  }

  .text-dark-small-extra-v6 {
    color: var(--dl-color-responsabilidade);
    font-weight: 999;
  }

  .img-especialidades2 {
    display: none;
  }

  @media (max-width: 1200px) {
    .home-right-section2 {
      width: 100%;
      flex-direction: column;
    }

    .img-especialidades {
      display: none;
    }

    .img-especialidades2 {
      display: inline;
      width: 100%;
      height: auto;
      border-radius: var(--dl-radius-radius-radius4);
    }

    .home-card {
      width: 80%;
    }
  }

  @media (max-width: 760px) {
  }

</style>
<template>
  <div id="services" class="home-features container-bg-light">
    <div class="home-hero-text3">
      <h2 class="home-pre-heading-v2">
        ENCONTRE O MAIS PERTO DE VOCÊ
      </h2>
      <h2 class="home-heading-v2 Section-Heading-v2">
        Conheça meus locais de atendimentos
      </h2>
    </div>
    <div class="home-cards-container3-v2">
      <div class="home-right-section2">
        <div class="home-content-container2-v2">
          <span class="home-text24-v2 SmallCard-Heading">
            GOIÂNIA HOSPITAL UNIQUE
          </span>
          <div class="home-card">
            <!-- <img
              alt="image"
              src="https://picsum.photos/id/221/500/300"
              class="home-image2-v2"
            /> -->
            <CarouselLocaisAtendimentoGO />
          </div>
          <span class="home-text24-v2 SmallCard-Content">
            Goiânia/GO
          </span>
        </div>
      </div>
    </div>
    <div class="home-cards-container3-v2">
      <div class="home-right-section2">
        <div class="home-content-container2-v2">
          <span class="home-text24-v2 SmallCard-Heading">
            PRIME CLÍNICA
          </span>
          <div class="home-card">
            <!-- <img
              alt="image"
              src="https://picsum.photos/id/222/500/300"
              class="home-image2-v2"
            /> -->
            <CarouselLocaisAtendimentoTO />
          </div>
          <span class="home-text24-v2 SmallCard-Content">
            Palmas/TO
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Carousel from './Carousel.vue';
  import CarouselLocaisAtendimentoGO from './CarouselLocaisAtendimentoGO.vue';
  import CarouselLocaisAtendimentoTO from './CarouselLocaisAtendimentoTO.vue';

  export default {
      name: "Services",
      components: {
        CarouselLocaisAtendimentoGO,
        CarouselLocaisAtendimentoTO
      },
      data() {
        return {
          rawvpwc: ' ',
          rawm3gm: ' ',
          rawyzqr: ' ',
        }
      },
  }
</script>

<style scoped>

  /* linha: 103 */
  .SmallCard-Heading {
    font-size: 18px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }

  /* linha: 113 */
  .SmallCard-Content {
    font-size: 16px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 500;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }

  /* linha: 183 */
  .Section-Heading-v2 {
    font-size: 48px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1.22;
    text-transform: none;
    text-decoration: none;
  }

  /* linha: 268 */
  .home-hero-text3 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  /* linha: 282 */
  .home-heading-v2
  .home-pre-heading-v2 {
    width: 50%;
    text-align: center;
    margin-bottom: var(--dl-space-space-fiveunits);
  }


  /* linha: 319 */
  .home-features {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-fiveunits);
    /* margin-bottom: var(--dl-space-space-fiveunits); */
    background-color:var(--dl-color-gray-1100);
    background-size: cover;
  }

  /* linha: 383  */
  .container-bg-light {
    background-color:var(--dl-color-gray-1100);
    background-size: cover;
  }

  /* linha: 994 */
  .home-cards-container3-v2 {
    width: 85%;
    display: flex;
    margin-top: var(--dl-space-space-doubleunit);
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }

  /* linha: 1137 */
  .home-right-section2 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: row;
    justify-content: space-between;
  }

  /* linha: 1148 */
  .home-card {
    width: 48%;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    flex-direction: column;
    justify-content: space-between;
  }

  /* linha: 1196 */
  .home-content-container2-v2 {
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }

  /* linha: 1210 */
  .home-text24-v2 {
    text-align: center;
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }

</style>
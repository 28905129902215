<template>
  <div id="especialidades" class="container-especialidades container-bg-light">
    <div class="container-especialidades-content">

      <div class="container-heading-especialidades">
        <h2 class="especialidades-text-light container-section-pre-heading-especialidades">
          PRINCIPAIS PROCEDIMENTOS
        </h2>
        <h2 class="especialidades-text container-section-heading-especialidades">
          Dê um passo em direção a recuperar sua auto estima e qualidade de vida
        </h2>
      </div>      

      <div class="container-cards-section-especialidades">
        <div class="container-group-card-especialidades">
          <div class="container-card-especialidades">
            <img
              alt="Morpheus"
              src="images/Morpheus-526x226px.jpg"
              class="img-especialidades"
              height="226"
              width="526"
            />
            <div class="container-especialidades-content-interno">
              <span class="especialidades-text-small container-small-card-heading-especialidades">
                Morpheus
              </span>
              <span class="especialidades-text-small container-small-card-content-especialidades text-light-small-extra2">
                Morpheus tecnologia de radiofrequência atua na derme, 
                estimulando o colágeno natural, promovendo um rejuvenescimento
                duradouro. Minimamente invasivo, melhora elasticidade, resistência,
                melhora as estrias, celulites e cicatrizes.
              </span>
            </div>
          </div>
          <div class="container-card-especialidades">
            <img
              alt="BodyTite"
              src="images/BodyTite-526x226px.jpg"
              class="img-especialidades"
              height="226"
              width="526"
            />
            <div class="container-especialidades-content-interno">
              <span class="especialidades-text-small container-small-card-heading-especialidades">
                BodyTite
              </span>
              <span class="especialidades-text-small container-small-card-content-especialidades text-light-small-extra2">
                BodyTite é uma inovação para tratar gordura localizada e
                amenizar a flacidez de pele com rápida recuperação. Usando
                radiofrequência bipolar, é seguro, eficaz promovendo significativa
                melhora estética.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="container-cards-section-especialidades">  
        <div class="container-group-card-especialidades">
          <div class="container-card-especialidades">
            <img
              alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
              src="images/LAD,-LMD-(Lipo-HD)-Vibro-Lipoescultura-526x226px.jpg"
              class="img-especialidades"
              height="226"
              width="526"
            />
            <div class="container-especialidades-content-interno">
              <span class="especialidades-text-small container-small-card-heading-especialidades">
                LAD, LMD (Lipo HD), Vibro-Lipoescultura
              </span>
              <span class="especialidades-text-small container-small-card-content-especialidades text-light-small-extra2">
                A Lipoaspiração de definição é uma técnica cirurgica avançada
                que visa esculpir o corpo, realçando os contornos musculares. 
                Promovendo um resultado mais natural, tirando o estigma da 
                clássica "lipoaspiração" pode ser combinada com outras cirurgias.
              </span>
            </div>
          </div>
          <div class="container-card-especialidades">
            <img
              alt="Silicone Mamário"
              src="images/Proteses-de-Recuperacao-Rapida - -Silicone-Mamario-526x226px.jpg"
              class="img-especialidades"
              height="226"
              width="526"
            />
            <div class="container-especialidades-content-interno">
              <span class="especialidades-text-small container-small-card-heading-especialidades">
                Próteses de Recuperação Rápida - Silicone Mamário
              </span>
              <span class="especialidades-text-small container-small-card-content-especialidades text-light-small-extra2">
                Descubra a evolução em recuperação proteses de silicone!
                Essa técnica de implante mamário, é a chave para uma jornada de
                recuperação suave e eficiente. Com tecnologia de ponta e 
                materiais de alta qualidade, você pode voltar para as atividades
                leves em menor tempo.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="container-cards-section-especialidades">
        <div class="container-group-card-especialidades2">
          <div class="container-card-especialidades-left">
            <div class="container-especialidades-content-interno">
              <img
                alt="Renuvion"
                src="images/Renuvion-526x226px.jpg"
                class="img-especialidades"
                height="226"
                width="526"
              />
              <span class="especialidades-text-small container-small-card-heading-especialidades">
                Renuvion
              </span>
              <span class="especialidades-text-small container-small-card-content-especialidades text-light-small-extra">
                Renuvion é uma tecnologia para redução de flacidez de pele. Este
                método é a combinação de Gás Hélio + Energia resultando o plasma. 
                Pode ser utilizado após a lipoaspiração, ou associado a outros procedimentos.
              </span>
            </div>
          </div>
          <div class="container-card-especialidades-left">
            <div class="container-especialidades-content-interno">
              <img
                alt="Morpheus"
                src="images/Mastopexia-com-Proteses-de-Silicone-526x226px.jpg"
                class="img-especialidades"
                height="226"
                width="526"
              />
              <span class="especialidades-text-small container-small-card-heading-especialidades">
                Mastopexia com Próteses de Silicone
              </span>
              <span class="especialidades-text-small container-small-card-content-especialidades text-light-small-extra">
                A mastopexia restaura firmeza e harmonia aos seios, reposicionando a pele com
                cicatrizes em T, L, I ou periareolar. Conhecida como "lifting de mama", 
                rejuvenesce o perfil, reduz aréolas e pode incluir, ou não implantes de silicone.
              </span>
            </div>
          </div>
          <div class="container-card-especialidades">
            <div class="container-especialidades-content-interno">
              <img
                alt="Morpheus"
                src="images/Lipoabdominoplastia-LMD - -Abdominoplastia-526x226px.jpg"
                class="img-especialidades"
                height="226"
                width="526"
              />
              <span class="especialidades-text-small container-small-card-heading-especialidades">
                Lipoabdominoplastia LMD - Abdominoplastia
              </span>
              <span class="especialidades-text-small container-small-card-content-especialidades text-light-small-extra">
                Lipoabdominoplastia HD ou MD é uma inovação na Cirurgia Plástica que combina a lipoaspiração e a
                abdominoplastia clássica em um único procedimento. Promovendo um resultado mais natural e
                harmônico para região abdominal.
              </span>
            </div>
          </div>
          <div class="container-card-especialidades-right">
            <div class="container-especialidades-content-interno">
              <img
                alt="Morpheus"
                src="images/Lifting-Facial-526x226px.jpg"
                class="img-especialidades"
                height="226"
                width="526"
              />
              <span class="especialidades-text-small container-small-card-heading-especialidades">
                Lifting Facial
              </span>
              <span class="especialidades-text-small container-small-card-content-especialidades text-light-small-extra">
                É a técnica indicada para pacientes com flacidez facial. Removemos o excesso de pele e reposicionamos
                a musculatura facial, resultando em um efeito de rejuvenescimento. O objetivo é suavizar rugas, elevar
                supercílios e tratar a "papada”.
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="container-cards-footer-especialidades"> -->
        <!-- <button class="btn-cta-especialidades container-button-style-especialidades button-especialidades" style="margin-top: 40px;">Conheça meu trabalho</button> -->
        <!-- <a class="btn-cta-especialidades container-button-style-especialidades button-especialidades" href="https://api.whatsapp.com/send/?phone=13981911233&amp;text=Ol%C3%A1%2C+gostaria+de+marcar+uma+consulta.">
          Conheça meu trabalho
        </a>
      </div> -->

      <div class="navbar-right">
        <div class="nav-btn">
          <a href="https://api.whatsapp.com/send/?phone=13981911233&amp;text=Ol%C3%A1%2C+gostaria+de+marcar+uma+consulta.">
            AGENDAR CONSULTA
          </a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
      name: "Especialidades",
      data() {
        return {
          rawvpwc: ' ',
          rawm3gm: ' ',
          rawyzqr: ' ',
        }
      },
  }
</script>

<style scoped>

  .navbar-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    /* margin-right: 2%; */
    /* margin-left: 2%; */
  }

  .nav-btn {
    /* color: var(--dl-color-gray-white); */
    color: var(--dl-color-seguranca);
    border-radius: var(--dl-radius-radius-radius8);
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-unit);
    /* background-color: var(--dl-color-gray-black); */
    background-color: var(--dl-color-responsabilidade);
    /* display: right; */
    
    /* border-color: var(--dl-color-gray-black); */
    /* border-width: 1px; */

    cursor: pointer;

    font-size: 18px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
  }
  
  .container-especialidades {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  
    padding-top: var(--dl-space-space-hundredpx);
    padding-bottom: var(--dl-space-space-hundredpx);
  }

  .container-small-card-heading-especialidades {
    font-size: 26px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
    padding-bottom: var(--dl-space-space-doubleunit);
  }

  .container-small-card-content-especialidades {
    font-size: 22px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
    padding-bottom: var(--dl-space-space-doubleunit);
  }

  .container-section-heading-especialidades {
    /* font-size: 48px; */
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1.22;
    text-transform: none;
    text-decoration: none;
    width: 50%;
  }

  .container-section-pre-heading-especialidades {
    /* font-size: 56px; */
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1.22;
    text-transform: none;
    text-decoration: none;
    width: 50%;
  }

  .container-especialidades-content {
    width: 70%;
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: column;
    margin-right: 10%;
    margin-left: 10%;
  }

  .container-heading-especialidades {
    width: 100%;
    display: flex;
    align-items: left;
    margin-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }

  .container-bg-light {
    background-color:var(--dl-color-gray-1100);
    background-size: cover;
  }

  .especialidades-text {
    width: 100%;
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
    color: var(--dl-color-responsabilidade);
    font-size: 48px;
    font-weight: 750;
  }

  .especialidades-text-light {
    width: 100%;
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
    color: var(--dl-color-leveza);
    font-weight: 750;
  }

  .container-cards-section-especialidades {
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .container-group-card-especialidades {
    width: 100%;
    /* height: auto; */
    display: flex;
    /* height: 100%; */
    flex-wrap: wrap;
    /* align-self: stretch; */
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: row;
    justify-content: space-between;
    padding: 0 32px;
  }

  .container-group-card-especialidades2 {
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    align-self: stretch;
    /* align-items: flex-start; */
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: row;
    justify-content: space-between;
    padding: 0 32px;
  }

  .container-card-especialidades {
    width: 48%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    /* border-color: var(--dl-color-gray-900); */
    border-color: var(--dl-color-responsabilidade);
    background-color: var(--dl-color-seguranca);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: space-between;
    padding: 32px;
  }

  .container-card-especialidades-left {
    width: 48%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    /* border-color: var(--dl-color-gray-900); */
    /* border-color: var(--dl-color-gray-black); */
    border-color: var(--dl-color-responsabilidade);
    background-color: var(--dl-color-seguranca);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: space-between;
    margin-right: 2%;
    padding: 32px;
  }
  .container-card-especialidades-right {
    width: 48%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    /* border-color: var(--dl-color-gray-900); */
    /* border-color: var(--dl-color-gray-black); */
    border-color: var(--dl-color-responsabilidade);
    background-color: var(--dl-color-seguranca);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: space-between;
    margin-left: 2%;
    padding: 32px;
  }

  .container-especialidades-content-interno {
    display: flex;
    /* align-items: flex-start; */
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }

  .especialidades-text-small {
    text-align: left;
    margin-bottom: var(--dl-space-space-doubleunit);
    color: var(--dl-color-responsabilidade);
    font-weight: 975;
    inline-size: auto;
    /* word-break: break-all;
    overflow-wrap: break-word; */

    /* text-align: justify; */
    /* text-justify: inter-word; */
  }

  .text-light-small-extra {
    /* os dois de baixo */
    color: var(--dl-color-seriedade);
    font-weight: 700;
  }
  
  .text-light-small-extra2 {
    /* os dois de cima */
    color: var(--dl-color-seriedade);
    font-weight: 700;
    line-height: 1.22;
  }

  .img-especialidades {
    width: 100%;
    height: auto;
    border-radius: var(--dl-radius-radius-radius4);
    margin-bottom: var(--dl-space-space-doubleunit);
  }

  @media (max-width: 1200px) {
    .container-card-especialidades {
      width: 100%;
      margin-bottom: 40px;
    }
    /* .container-card-especialidades-left {
      width: 100%;
    }
    .container-card-especialidades-right {
      width: 100%;
    } */

    .container-group-card-especialidades2 {
      width: 100%;
      flex-direction: column;
      margin-bottom: 40px;
    }
    .container-especialidades-content-interno {
      width: 100%;
    }
    .container-card-especialidades-left {
      width: 100%;
      margin-bottom: 40px;
    }
    .container-card-especialidades-right {
      width: 100%;
      margin-bottom: 40px;
    }

    .container-section-heading-especialidades {
      width: 100%;
      text-align: justify;
      text-justify: inter-word;
      font-weight: 750;
    }    

    .container-section-pre-heading-especialidades {
      width: 100%;
      text-align: justify;
      text-justify: inter-word;
    }

    .container-especialidades-content {
      width: 95%;
    }

    .container-heading-especialidades {
      width: 95%;
    }
  }

  @media (max-width: 500px) {
    .container-small-card-heading-especialidades {
      font-size: 18px;
      font-style: normal;
      font-family: Raleway;
      font-weight: 700;
      line-height: 1.55;
      text-transform: none;
      text-decoration: none;
      padding-bottom: var(--dl-space-space-doubleunit);
    }

    .container-small-card-content-especialidades {
      font-size: 16px;
      font-style: normal;
      font-family: Raleway;
      font-weight: 700;
      line-height: 1.55;
      text-transform: none;
      text-decoration: none;
      padding-bottom: var(--dl-space-space-doubleunit);
    }
  }

</style>
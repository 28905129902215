<template>
  <div class="home-section-separator2"></div>
</template>

<script>
  export default {
      name: "SectionSeparator",
      data() {
        return {
          rawvpwc: ' ',
          rawm3gm: ' ',
          rawyzqr: ' ',
        }
      },
  }
</script>

<style scoped>
  /* linha: 1481 */
  .home-section-separator2 {
    width: 100%;
    height: 1px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
</style>
<template>
  <div id="contato" class="home-features container-bg-dark">
    <div class="home-cards-container3-v2">
      
      <div class="home-form-container">
        <!-- <div class="home-hero-text3"> -->
          <div class="home-form-container-v3">
            <div class="home-form-container-v3">
              <h3 class="home-pre-heading-v2 text-light-small-extra-v10">
                Você tem alguma dúvida sobre como marcar uma consulta ou sobre o meu atendimento?
              </h3>
            </div>
            <div class="home-form-container-v3">
              <h2 class="home-heading-v2 Section-Heading-v2 text-dark-small-extra-v10">
                Envie-nos uma mensagem para entrar em contato com nossa equipe. Estamos prontos para
                atendê-lo o mais breve possível e esclarecer todas as suas dúvidas!
              </h2>
            </div>
            <div class="home-form-container-v3">
              <div class="nav-btn">
                <a href="https://api.whatsapp.com/send/?phone=13981911233&amp;text=Ol%C3%A1%2C+gostaria+de+marcar+uma+consulta.">
                  Agendar Consulta
                </a>
              </div>
            </div>
          </div>
          <!-- <div class="container-cards-footer-especialidades">
            <button class="home-cta-btn1 AnchorButton button">Conheça meu trabalho</button>
          </div> -->
          
          <div class="home-form-container-v3">
            <h4 class="home-pre-heading-v2 text-dark-small-extra-v10">
              (62) 99400-6421
            </h4>
            <h4 class="home-pre-heading-v2 text-dark-small-extra-v10">
              contato@antonioplastica.com
            </h4>
            <h4 class="home-pre-heading-v2 text-dark-small-extra-v10">
              Horário comercial: 09h - 18h | Segunda a Sexta
            </h4>
            <h4 class="home-pre-heading-v2 text-dark-small-extra-v10">
              GOIÂNIA HOSPITAL UNIQUE:
                AV. T-3, 2199 - 13, 14 e 15 - St. Bueno - Goiânia/GO
            </h4>
            <h4 class="home-pre-heading-v2 text-dark-small-extra-v10">
              PRIME CLÍNICA:
                Qd.204 Sul Al.16 - Palmas/TO
            </h4>
          </div>
          <!-- <span>
            Adicionar os js dos endereços!
          </span> -->
        <!-- </div> -->
      </div>
      <div class="home-form-container2">
        <div class="home-content-container6 container-bg-light">
          <div class="home-form-container3">
            <span class="home-heading08 BigCard-Heading">
              <img
                alt="Logo Formulário"
                src="images/Logo Antonio Teixeira VERTICAL.png"
                class="img-especialidades"
                width="250px"
                
              />
            </span>
            <input
              type="text"
              required="true"
              placeholder="Nome"
              class="home-name5 input"
            />
            <input
              type="text"
              required="true"
              placeholder="Whatsapp"
              class="home-email input"
            />
            <input
              type="text"
              required="true"
              placeholder="E-mail"
              class="home-email input"
            />
            <textarea
              placeholder="Assunto"
              class="home-message textarea"
            ></textarea>
            <button class="home-cta-btn2 Anchor button nav-btn">Enviar Mensagem</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
      name: "Contato",
      data() {
        return {
          rawvpwc: ' ',
          rawm3gm: ' ',
          rawyzqr: ' ',
        }
      },
  }
</script>

<style scoped>

  /* linha: 52 */
  .button {
    color: var(--dl-color-gray-black);
    display: inline-block;
    padding: 0.2rem 0.3rem;
    font-family: Raleway;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
  }

  /* linha: 63 */
  .textarea {
    color: var(--dl-color-gray-black);
    cursor: auto;
    padding: 0.5rem;
    font-family: Raleway;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
  }

  /* linha: 74 */
  .input {
    color: var(--dl-color-gray-black);
    cursor: auto;
    padding: 0.5rem 1rem;
    font-family: Raleway;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
  }

  /* linha: 133 */
  .BigCard-Heading {
    font-size: 36px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }

  /* linha: 153 */
  .Anchor {
    font-size: 18px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
  } 

  /* linha: 183 */
  .Section-Heading-v2 {
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1.22;
    text-transform: none;
    text-decoration: none;
  }

  /* linha: 283 */
  .home-heading-v2
  .home-pre-heading-v2 {
    width: 100%;
    text-align: center;
    margin-bottom: var(--dl-space-space-fiveunits);
  }

  /* linha: 320 */
  .home-features {
    width: 100%;
    display: flex;
    /* max-width: var(--dl-size-size-maxcontent); */
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
    /* margin-bottom: var(--dl-space-space-fiveunits); */
    background-color:var(--dl-color-gray-1100);
    background-size: cover;
  }

  /* linha: 384 */
  .container-bg-light {
    background-color:var(--dl-color-gray-1100);
    background-size: cover;
  }

  /* linha: 389 */
  .container-bg-dark {
    background-color:var(--dl-color-gray-900);
    background-size: cover;
  }

  /* linha: 995 */
  .home-cards-container3-v2 {
    /* width: 85%; */
    display: flex;
    margin-top: var(--dl-space-space-doubleunit);
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  /* linha: 1506 */
  .home-content-container6 {
    width: 100%;
    display: flex;
    align-self: stretch;
    margin-top: var(--dl-space-space-fiveunits);
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  /* linha: 1515 */
  .home-form-container {
    width: 60%;
    display: flex;
    align-self: center;
    align-items: center;
    padding-top: var(--dl-space-space-fiveunits);
    /* margin-right: var(--dl-space-space-fiveunits); */
    margin-right: 0;
    /* padding-left: var(--dl-space-space-tenunits); */
    padding-left: 0;
    border-radius: var(--dl-radius-radius-radius8);
    /* padding-right: var(--dl-space-space-tenunits); */
    /* padding-right: 0; */
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fiveunits);
    background-color: var(--dl-color-gray-900);
    justify-content: center;
  }

  .home-form-container-v3 {
    /* width: 100%; */
    display: flex;
    align-items: center;
    /* margin-right: var(--dl-space-space-fiveunits); */
    margin-right: 0;
    /* padding-left: var(--dl-space-space-tenunits); */
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
    background-color: var(--dl-color-gray-900);
    justify-content: center;
  }

  .home-form-container2 {
    width: 100%;
    /* height: 150%; */
    display: flex;
    align-self: stretch;
    align-items: center;
    /* padding-top: var(--dl-space-space-fiveunits); */
    /* margin-right: var(--dl-space-space-fiveunits); */
    margin-right: 0;
    /* padding-left: var(--dl-space-space-tenunits); */
    padding-left: 0;
    border-radius: var(--dl-radius-radius-radius8);
    /* padding-right: var(--dl-space-space-tenunits); */
    padding-right: 0;
    flex-direction: column;
    /* padding-bottom: var(--dl-space-space-fiveunits); */
    background-color: var(--dl-color-gray-900);
  }
  .home-form-container3 {
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    padding-top: var(--dl-space-space-fiveunits);
    /* margin-right: var(--dl-space-space-fiveunits); */
    margin-right: 0;
    /* padding-left: var(--dl-space-space-tenunits); */
    padding-left: 0;
    /* border-radius: var(--dl-radius-radius-radius8); */
    /* padding-right: var(--dl-space-space-tenunits); */
    padding-right: 0;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fiveunits);
    background-color: var(--dl-color-gray-900);
  }

  /* linha: 1529 */
  .home-heading08 {
    margin-bottom: var(--dl-space-space-fourunits);
  }

  /* linha: 1532 */
  .home-name5 {
    width: 100%;
    border: none;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }

  /* linha: 1542 */
  .home-email {
    width: 100%;
    border: none;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }

  /* linha: 1552 */
  .home-message {
    width: 100%;
    border: none;
    height: 140px;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }

  /* linha: 1563 */
  .home-cta-btn2 {
    color: var(--dl-color-gray-white);
    text-align: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-tenunits);
    border-radius: var(--dl-radius-radius-radius24);
    padding-right: var(--dl-space-space-tenunits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-gray-black);
  }

  .container-card-especialidades-left {
    width: 48%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    /* border-color: var(--dl-color-gray-900); */
    border-color: var(--dl-color-gray-1100);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: space-between;
    margin-right: 2%;
    padding: 32px;
  }

  .container-cards-footer-especialidades {
    width: 100%;
    /* height: 100%; */
    display: flex;
    margin-top: var(--dl-space-space-doubleunit);
    align-items: center;
    justify-content: center;
    /* flex-direction: row; */
  }

  .text-light-small-extra-v10 {
    color: var(--dl-color-seriedade);
    font-weight: 700;
  }

  .text-dark-small-extra-v10 {
    color: var(--dl-color-responsabilidade);
    font-weight: 999;
  }

  .nav-btn {
    /* color: var(--dl-color-gray-white); */
    color: var(--dl-color-seguranca);
    border-radius: var(--dl-radius-radius-radius8);
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-unit);
    /* background-color: var(--dl-color-gray-black); */
    background-color: var(--dl-color-responsabilidade);
    /* display: right; */
    
    /* border-color: var(--dl-color-gray-black); */
    /* border-width: 1px; */

    cursor: pointer;

    font-size: 18px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
  }

</style>
<template>
  <div id="hospital-services" class="home-features container-bg-dark">
    <div class="hospital-services-content">
      <div class="hospital_services_heading">
        <h2 class="text-light-small-extra-v4">HOSPITAIS</h2>
        <h2 class="Section-Heading-v2 text-dark-small-extra-v4">Conheça meus locais de cirurgia</h2>
      </div>
      <LocaisDeCirurgia />
      <!-- <div class="hospital_services_cards_containers">
        <div class="home-service-card">
          <h3 class="home-text10 Card-Heading text-dark-small-extra-v4">GOIÂNIA</h3>
          <div class="container-cards-section-especialidades">
            <div class="container-group-card-especialidades2">
              <div class="container-card-especialidades-left">
                <img
                  alt="Morpheus"
                  src="images/Mastopexia-com-Proteses-de-Silicone-526x226px.jpg"
                  class="img-especialidades"
                  height="226"
                  width="526"
                />
                <div class="container-especialidades-content-interno">
                  <span class="especialidades-text-small container-small-card-heading-especialidades text-light-small-extra-v5">
                    ALBERT EINSTEIN ORION
                  </span>
                </div>
              </div>
              <div class="container-card-especialidades">
                <img
                  alt="Morpheus"
                  src="images/Lipoabdominoplastia-LMD - -Abdominoplastia-526x226px.jpg"
                  class="img-especialidades"
                  height="226"
                  width="526"
                />
                <div class="container-especialidades-content-interno">
                  <span class="especialidades-text-small container-small-card-heading-especialidades text-light-small-extra-v5">
                    UNIQUE
                  </span>
                </div>
              </div>
              <div class="container-card-especialidades-right">
                <img
                  alt="Morpheus"
                  src="images/lipoenxertia_glutea.jpg"
                  class="img-especialidades"
                  height="226"
                  width="526"
                />
                <div class="container-especialidades-content-interno">
                  <span class="especialidades-text-small container-small-card-heading-especialidades text-light-small-extra-v5">
                    IRG
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home-service-card1">
          <h3 class="home-text12 Card-Heading text-dark-small-extra-v4">PALMAS</h3>    
          <div class="container-card-especialidades-left2">
            <img
              alt="Morpheus"
              src="images/Lipoabdominoplastia-LMD - -Abdominoplastia-526x226px.jpg"
              class="img-especialidades"
              height="226"
              width="526"
            />
            <div class="container-especialidades-content-interno">
              <span class="especialidades-text-small container-small-card-heading-especialidades text-light-small-extra-v5">
                MEDICAL SANTA THEREZA
              </span>
            </div>
          </div>          
        </div>
      </div> -->
    </div>


  </div>
</template>

<script>
  import LocaisDeCirurgia from './LocaisDeCirurgia.vue';
  export default {
      name: "HospitalServices",
      components: {
        LocaisDeCirurgia
      },
      data() {
        return {
          rawvpwc: ' ',
          rawm3gm: ' ',
          rawyzqr: ' ',
        }
      },
  }
</script>

<style scoped>

  /* linha: 143 */
  /* .Card-Heading {
    font-size: 24px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  } */

  /* linha: 183 */
  .Section-Heading-v2 {
    font-size: 48px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1.22;
    text-transform: none;
    text-decoration: none;
    text-align: center;
    width: 100%;
    /* 
    margin-bottom: var(--dl-space-space-fiveunits); 
    */
  }

  /* linha: 320 */
  .home-features {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    padding-top: var(--dl-space-space-hundredpx);
    padding-bottom: var(--dl-space-space-hundredpx);
    padding-left: 0;
    padding-right: 0;
    /* padding-top: var(--dl-space-space-tenunits);
    padding-bottom: var(--dl-space-space-fiveunits); */
    /* padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit); */
    /* margin-bottom: var(--dl-space-space-fiveunits); */
    /* background-color:var(--dl-color-gray-1100); */
    /* background-size: cover; */
  }

  .hospital-services-content {
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-tenunits);

    /* padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit); */
  }

  /* linha: 389 */
  .container-bg-dark {
    background-color:var(--dl-color-gray-900);
    background-size: cover;
  }

  /* linha: 658 */
  .hospital_services_heading {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }

  /* linha: 696 */
  .home-service-card {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: center;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-900);
  }

  /* linha: 717 */
  /* .home-text10 {
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  } */

  /* linha: 721 */
  .home-text11 {
    color: var(--dl-color-gray-700);
    text-align: left;
    margin-bottom: var(--dl-space-space-fourunits);
  }

  /* linha: 726 */
  .home-service-card1 {
    width: 68%;
    display: flex;
    padding: var(--dl-space-space-fourunits);
    /* align-items: flex-start; */
    align-items: center;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-900);
  }

  /* linha: 736 */
  .home-text12 {
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }

  .hospital_services_cards_containers {
    width: 100%;
    display: flex;
    margin-top: 0;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .container-small-card-heading-especialidades {
    font-size: 18px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }

  .container-small-card-content-especialidades {
    font-size: 16px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 500;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }

  .container-cards-section-especialidades {
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .container-cards-section-especialidades2 {
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .container-group-card-especialidades2 {
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    align-self: stretch;
    /* align-items: flex-start; */
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: row;
    justify-content: space-between;
    padding: 0 32px;
  }

  .container-card-especialidades {
    width: 48%;
    height: 100%;
    display: flex;
    align-items: center;
    /* border-color: var(--dl-color-gray-900); */
    /* border-color: var(--dl-color-gray-1100); */
    border-color: var(--dl-color-responsabilidade);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: space-between;
    padding: 18px;
    background-color: var(--dl-color-elegancia);
  }

  .container-card-especialidades-left {
    width: 48%;
    height: 100%;
    display: flex;
    align-items: center;
    /* border-color: var(--dl-color-gray-900); */
    border-color: var(--dl-color-responsabilidade);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: center;
    margin-right: 2%;
    padding: 18px;
    background-color: var(--dl-color-elegancia);
  }

  .container-card-especialidades-left2 {
    width: 48%;
    height: 100%;
    display: flex;
    align-items: center;
    /* border-color: var(--dl-color-gray-900); */
    border-color: var(--dl-color-responsabilidade);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: center;
    margin-right: 2%;
    padding: 18px;
    background-color: var(--dl-color-elegancia);
  }

  .container-card-especialidades-right {
    width: 48%;
    height: 100%;
    display: flex;
    align-items: center;
    /* border-color: var(--dl-color-gray-900); */
    border-color: var(--dl-color-responsabilidade);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: space-between;
    margin-left: 2%;
    padding: 18px;
    background-color: var(--dl-color-elegancia);
  }

  .container-especialidades-content-interno {
    display: flex;
    /* align-items: flex-start; */
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }

  .especialidades-text-small {
    text-align: left;
    margin-bottom: var(--dl-space-space-doubleunit);

    /* text-align: justify; */
    /* text-justify: inter-word; */
  }

  .img-especialidades {
    width: 100%;
    height: auto;
    border-radius: var(--dl-radius-radius-radius4);
  }

  .text-dark-small-extra-v4 {
    color: var(--dl-color-responsabilidade);
    /* font-weight: 999; */
  }

  .text-light-small-extra-v4 {
    color: var(--dl-color-seriedade);
    /* color: var(--dl-color-leveza); */
    /* font-weight: 700; */
  }

  .text-dark-small-extra-v5 {
    color: var(--dl-color-responsabilidade);
    /* font-weight: 999; */
  }

  .text-light-small-extra-v5 {
    color: var(--dl-color-responsabilidade);
    /* font-weight: 700; */
  }
  

</style>
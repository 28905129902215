<template>
  <div class="topo">
    <div class="navbar" >
      <div class="">
        <img
          alt="Logo"
          src="images/Site.png"
          class="logo-burger" @click="scrollToTop(); closeMenu();"
          width="350"
          height="auto"
        />
      </div>
    <div class="navbar-center">
      <input type="checkbox" id="menu-toggle" class="menu-toggle" v-model="isMenuOpen">
      <label for="menu-toggle" class="burger-icon">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </label>
      <ul class="navbar-links" :class="{ 'active': isMenuOpen }">
        <li v-for="link in navLinks" :key="link.id" class="navbar-item">
          <a @click="scrollToSection(link.id); closeMenu();" class="nav-link">{{ link.text }}</a>
        </li>
      </ul>
    </div>
    <div class="navbar-right">
      <div class="nav-btn">
        <a href="https://api.whatsapp.com/send/?phone=13981911233&amp;text=Ol%C3%A1%2C+gostaria+de+marcar+uma+consulta.">
          AGENDAR CONSULTA
        </a>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  props: {
    navLinks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  computed: {
    isResponsive() {
      return window.innerWidth <= 1200 || window.innerWidth <= 1020 || window.innerWidth <= 520 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
  methods: {
    scrollToSection(id) {
      this.$emit("scrollToSection", id);
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    scrollToTop: debounce(function() {
      window.scrollTo(0, 0);
    }, 300),
  },
  created() {
    window.addEventListener("resize", debounce(() => {
      if (!this.isResponsive) {
        this.isMenuOpen = false;
      }
    }, 300));
    document.addEventListener("click", (event) => {
      if (this.isMenuOpen && !event.target.closest(".navbar")) {
        this.closeMenu();
      }
    });
  },
};
</script>

<style scoped>

  .topo {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--dl-color-seguranca);
    z-index: 10;
    width:  100%;
    height: 10vh;
  }

  /* Estilos para dispositivos maiores (não móveis) */
  .navbar {
    /* position: fixed; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* widows: 100%; */
    width: 85%;
    /* height: auto; */
    /* margin-left: 3.5%;
    margin-right: 3.5%;
    margin-bottom: 0.5%; */
    /* margin-top: 0.5%; */
    /* height: 100px; */
    padding-left: 3.5%;
    padding-right: 3.5%;
    padding-bottom: 2.0%;
    padding-top: 2.0%;
    /* background-color: var(--dl-color-seguranca); */
  }

  .burger-icon {
    display: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
  }

  .burger-icon .bar {
    width: 20px;
    height: 4px;
    background-color: #000;
    margin: 3px auto;
    transition: 0.4s;
  }

  .navbar-links {
    width: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Espaço igual entre os elementos */
    margin: 0; /* Remova a margem padrão da lista */
    padding: 0; /* Remova o preenchimento padrão da lista */
  }

  .navbar-center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-right: 2%;
    margin-left: 2%;
  }

  .navbar-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
  }

  .navbar-item {
    display: inline-block;
    align-items: center;
    cursor: pointer;  
    margin-right: 2%;
    margin-left: 2%;
  }

  .nav-link {
    text-decoration: none;
    color: var(--dl-color-responsabilidade);
    font-weight: bold;
    font-size: 24px;
  }

  .menu-toggle {
    display: none;
  }

  .nav-btn {
    color: var(--dl-color-leveza);
    border-radius: var(--dl-radius-radius-radius8);
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-responsabilidade);

    cursor: pointer;

    font-size: 18px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
  }

  li:hover {
    color: var(--dl-color-responsabilidade);
    height: 60px;
    font-weight: bold;
  }

  .nav-link:hover {
    color: var(--dl-color-responsabilidade);
    font-weight: bold;
  }

  @media (max-width: 1200px) {
    .topo {
      position: fixed;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      background-color: var(--dl-color-seguranca);
      z-index: 10;
      width:  100%;
      height: 10vh;
      padding: 0px;
    }

    .navbar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 93%;
      padding: 0px;
    }

    .navbar-center {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60%;
    }

    .navbar-right {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15%;
    }
  }

  /* Estilos para dispositivos móveis */
  @media (max-width: 1020px) {
    .topo {
      position: fixed;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      background-color: var(--dl-color-seguranca);
      z-index: 10;
      width:  100%;
      height: 10vh;
    }

    .navbar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0px;
    }
    .burger-icon {
      display: flex;
    }

    .menu-toggle {
      display: none;
    }

    .navbar-links {
      list-style: none;
      display: none;
      flex-direction: column; /* Alteração para mostrar os itens verticalmente */
      position: absolute; /* Alteração para sobrepor o ícone */
      top: 30px; /* Espaçamento mínimo entre o ícone e o menu (ajuste conforme necessário) */
      right: 0;
      background-color: #fff;
      padding: 10px;
    }

    .navbar-links.active {
      display: flex;
      flex-direction: column; /* Alteração para mostrar os itens verticalmente */
      position: absolute; /* Alteração para sobrepor o ícone */
      top: 100%; 
      right: 0;
      background-color: var(--dl-color-seguranca);
      padding: 10px;
    }

    .navbar-item {
      margin-right: 0;
      margin-bottom: 10px;
    }

    .logo-burger {
      /* width: 100%;
      height: auto; */
    }
    .navbar-center {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      margin-right: 0;
      margin-left: 2%;
    }

    .navbar-right {
      display: none;
      width: 0;
      height: 0;
      margin: 0;
    }
  }

  @media (max-width: 500px) {
    .topo {
      /* position: relative; */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      background-color: var(--dl-color-seguranca);
      z-index: 10;
      width: 100%;
      padding: 0;
      height: 10vh;
    }

    .navbar {
      /* position: relative; */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0;
    }

    .logo-burger{
      width: 250px;
    }
  }
</style>

<template>
  <div id="contato" class="home-features container-bg-dark">
    <div class="container_contato_content container-bg-dark">
      <div class="container_contato_form">
        <span class="home-heading08 BigCard-Heading">
          <img
            alt="Logo Formulário"
            src="images/ANTONIO TEIXEIRA - RGB-03.png"
            class="img-especialidades"
            height="100%"
            width="400"
          />
        </span>
        <input
          type="text"
          required="true"
          placeholder="Nome"
          class="home-name5 input"
        />
        <input
          type="text"
          required="true"
          placeholder="Whatsapp"
          class="home-email input"
        />
        <input
          type="text"
          required="true"
          placeholder="E-mail"
          class="home-email input"
        />
        <textarea
          placeholder="Assunto"
          class="home-message textarea"
        ></textarea>
        <button class="home-cta-btn2 Anchor button nav-btn">ENVIAR MENSAGEM</button>
      </div>

      <div class="container_contato_infos">
        <div class="home-form-container-v3">
          <h3 class="home-pre-heading-v2 text-light-small-extra-v10">
            Você tem alguma dúvida sobre como marcar uma consulta ou sobre o meu atendimento?
          </h3>
        </div>
        <div class="home-form-container-v3">
          <h2 class="home-heading-v2 Section-Heading-v2 text-dark-small-extra-v10">
            Envie-nos uma mensagem para entrar em contato com nossa equipe. Estamos prontos para
            atendê-lo o mais breve possível e esclarecer todas as suas dúvidas!
          </h2>
        </div>
        <div class="home-form-container-v3">
          <div class="nav-btn">
            <a href="https://api.whatsapp.com/send/?phone=13981911233&amp;text=Ol%C3%A1%2C+gostaria+de+marcar+uma+consulta.">
              AGENDAR CONSULTA
            </a>
          </div>
        </div>
        <div class="home-form-container-v3">
          <h4 class="home-pre-heading-v2 text-dark-small-extra-v10">
            (62) 99400-6421
          </h4>
          <h4 class="home-pre-heading-v2 text-dark-small-extra-v10">
            contato@antonioplastica.com
          </h4>
          <h4 class="home-pre-heading-v2 text-dark-small-extra-v10">
            Horário comercial: 09h - 18h | Segunda a Sexta
          </h4>
          <h4 class="home-pre-heading-v2 text-dark-small-extra-v10">
            GOIÂNIA HOSPITAL UNIQUE:
              AV. T-3, 2199 - 13, 14 e 15 - St. Bueno - Goiânia/GO
          </h4>
          <h4 class="home-pre-heading-v2 text-dark-small-extra-v10">
            PRIME CLÍNICA:
              Qd.204 Sul Al.16 - Palmas/TO
          </h4>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
      name: "ContatoVisualization",
      data() {
        return {
          rawvpwc: ' ',
          rawm3gm: ' ',
          rawyzqr: ' ',
        }
      },
  }
</script>

<style scoped>

  /* linha: 320 */
  .home-features {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
    background-color:var(--dl-color-gray-1100);
    background-size: cover;
  }
  .container_contato_content {
    width: 85%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    /* padding-bottom: var(--dl-space-space-fiveunits); */
    flex-direction: column;
    background-color:var(--dl-color-gray-1100);
    background-size: cover;
  }

  /* linha: 389 */
  .container-bg-dark {
    background-color:var(--dl-color-gray-900);
    background-size: cover;
  }

  .container_contato_form {
    width: 100%;
    display: flex;

    align-self: center;
    align-items: center;

    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);

    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }

  .container_contato_infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
    padding-left: 0;
    margin-right: 0;
    border-radius: var(--dl-radius-radius-radius8);
    background-color: var(--dl-color-gray-900);
  }

  .home-form-container-v3 {
    width: 80%;
    display: flex;
    align-items: center;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    padding-top: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-tripleunit);
    background-color: var(--dl-color-gray-900);
    justify-content: center;
  }

  .nav-btn {
    color: var(--dl-color-seguranca);
    border-radius: var(--dl-radius-radius-radius8);
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-responsabilidade);

    cursor: pointer;

    font-size: 18px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
  }

  .home-pre-heading-v2 {
    /* width: 100%; */
    text-align: center;
    /* margin-bottom: var(--dl-space-space-fiveunits); */
  }

  .home-heading-v2 {
    /* width: 100%; */
    text-align: center;
    /* margin-bottom: var(--dl-space-space-fiveunits); */
  }

  .text-light-small-extra-v10 {
    color: var(--dl-color-seriedade);
    font-weight: 700;
  }

  .Section-Heading-v2 {
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1.22;
    text-transform: none;
    text-decoration: none;
  }

  .text-dark-small-extra-v10 {
    color: var(--dl-color-responsabilidade);
    font-weight: 999;
  }

  @media (max-width: 400px) {
    .img-especialidades {
      width: 250px;
    }
  }

</style>
<template>
  <div id="inicio" class="container-inicio container-bg-light">

    <div class="container-inicio-conteudo">
      
      <div class="container-inicio-texto container-inicio-half">
        <h2 class="container-pre-heading-inicio">
          EXCELÊNCIA EM CIRURGIA PLÁSTICA
        </h2>

        <h2 class="container-heading-inicio section-heading-inicio">
          Encontre a sua melhor versão
        </h2>

        <span class="home-text section-text-inicio">
          Seja bem vinda! Escolha o seu procedimento e agende sua consulta agora. Nossa equipe altamente capacitada está à disposição para esclarecer todas as suas dúvidas.
        </span>

        <div class="container-inicio-conteudo-btns">
            <a class="btn-inicio btn-text-inicio btn-inicio-estilo" href="https://api.whatsapp.com/send/?phone=13981911233&amp;text=Ol%C3%A1%2C+gostaria+de+marcar+uma+consulta.">
              AGENDAR CONSULTA
            </a>
            &nbsp;
            &nbsp;
            <a class="btn-inicio btn-text-inicio btn-inicio-estilo" href="#">
              CONHEÇA MEU TRABALHO
            </a>
            <!-- <button class="btn-inicio btn-text-inicio btn-inicio-estilo">
              <a class="btn-inicio btn-text-inicio btn-inicio-estilo" href="https://api.whatsapp.com/send/?phone=13981911233&amp;text=Ol%C3%A1%2C+gostaria+de+marcar+uma+consulta.">
                Agendar Consulta
              </a>
            </button> -->
            <!-- <div > -->
              <!-- <div> -->
                
              <!-- </div> -->
            <!-- </div> -->
        </div>
      </div>

      <div class="container-inicio-texto container-inicio-half">
        <img
          alt="Logo"
          src="images/ANTONIO PLASTICA-231 copy.jpg"
          class="image-inicio-right"
        />
      </div>

    </div>

  </div>
</template>

<script>
  export default {
      name: "Inicio",
      data() {
        return {
          rawvpwc: ' ',
          rawm3gm: ' ',
          rawyzqr: ' ',
        }
      },
  }
</script>

<style scoped>

  .container-bg-light {
    background-color:var(--dl-color-elegancia);
    background-size: cover;
  }

  .btn-inicio {
    color: var(--dl-color-elegancia);
    text-align: center;
    
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    
    border-radius: 8px;

    display: inline-block;

    background-color: var(--dl-color-responsabilidade);

    font-size: 16px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
  }

  .section-text-inicio {
    font-size: 18px;
    font-family: Raleway;
    font-weight: 400;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }

  .section-heading-inicio {
    font-size: 48px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1.22;
    text-transform: none;
    text-decoration: none;
  }

  .container-inicio {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  
    padding-top: var(--dl-space-space-hundredpx);
    padding-bottom: var(--dl-space-space-hundredpx);
  }

  .container-inicio-conteudo {
    width: 67%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    
    padding-top: var(--dl-space-space-tenunits);
    /* padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit); */
    padding-bottom: var(--dl-space-space-tenunits);

    /* background-size: cover;
    background-image: linear-gradient(to left, transparent 0%, #fff 50%), image-set("./images/ANTONIO PLASTICA-231.jpg"); */
    /* background-image: image-set("./images/ANTONIO PLASTICA-231.jpg"); */
    /* background-image: url("https://picsum.photos/id/221/1063/800"); */
    /* background-image: image-set("./images/ANTONIO PLASTICA-231.jpg");
    background-size: 500px 700px ;
    background-repeat: no-repeat;
    background-position: right; */
  }

  .container-inicio-conteudo-btns {
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    /* margin-left: 5%;
    margin-right: 5%; */
    /* padding-top: var(--dl-space-space-tenunits); */
    /* padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit); */
    /* padding-bottom: var(--dl-space-space-tenunits); */
  }

  .container-inicio-texto {
    /* width: 80%; */
    display: flex;
    align-items: left;
    flex-direction: column;
  }

  .container-pre-heading-inicio {
    /* width: 50%; */
    text-align: left;
    margin-bottom: var(--dl-space-space-unit);

    text-align: justify;
    text-justify: inter-word;

    color: var(--dl-color-leveza);
    font-weight: 750;
  }

  .container-heading-inicio {
    /* width: 50%; */
    text-align: left;
    margin-bottom: var(--dl-space-space-fiveunits);

    text-align: justify;
    text-justify: inter-word;

    color: var(--dl-color-responsabilidade);
    font-weight: 750;
  }

  .container-inicio-half {
    width: 50%;
  }

  .home-text {
    /* width: 100%; */
    /* color: var(--dl-color-gray-700); */
    color: var(--dl-color-responsabilidade);
    font-weight: 600;
    text-align: left;
    margin-bottom: var(--dl-space-space-fiveunits);

    text-align: justify;
    text-justify: inter-word;
  }

  .image-inicio-right {
    width: 90%;
    height: auto;
    
    /* padding-top: var(--dl-space-space-unit); */
    /* padding-left: var(--dl-space-space-doubleunit); */
    /* padding-right: var(--dl-space-space-doubleunit); */
    /* padding-bottom: var(--dl-space-space-unit); */

    border-radius: var(--dl-radius-radius-radius8);

    margin-left: 10%;
    margin-right: 10%;
  }

  @media (max-width: 1200px) {
    .container-inicio-conteudo {
      width: 100%;
      flex-direction: column;
    }

    .btn-inicio{
      font-size: 14px;
    }

    .container-inicio-conteudo-btns {
      width: 100%;
    }

    .container-inicio {
      width: 100%;
    }

    .container-inicio-conteudo-btns {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      padding-bottom: var(--dl-space-space-tripleunit);
    }

    .btn-inicio {
      color: var(--dl-color-elegancia);
      text-align: center;
      
      border-radius: 8px;

      background-color: var(--dl-color-responsabilidade);
    }

    .container-inicio-half {
      width: 80%;
    }
  }

  @media (max-width: 760px) {
    .image-inicio-right {
      display: none;
    }

    .btn-inicio{
      font-size: 14px;
    }

    .container-inicio-conteudo-btns {
      width: 100%;
    }

    .container-inicio {
      width: 100%;
    }
  }

</style>
<template>
  <div id="exibition" class="exibition-features container-bg-dark">
    <div class="exibition-content2">

      <div class="exibition-content2">
        <h2 class="text-dark-small-extrav2">
          Por que me escolher para fazer o seu procedimento?
        </h2>
      </div>

      <div class="exibition-content">

        <div class="exibition-content2-v2 border">
          <div class="home-heading text-dark-small-extrav2">
              A sua segurança em 1° lugar
          </div>
          <div class="home-text Exibition-Text text-light-small-extrav2 ">
            <span>&#10003;</span> Ampla experiência em milhares de cirurgias plásticas bem-sucedidas,
            com mais de 15 anos de dedicação a esta especialidade.
          </div>
          <div class="home-text Exibition-Text text-light-small-extrav2 ">
            <span>&#10003;</span> Experiência em clínicas e renomados hospitais altamente conceituados.
          </div>
          <div class="home-text Exibition-Text text-light-small-extrav2 ">
            <span>&#10003;</span> Contamos com uma equipe anestésica altamente experiente, reconhecida pela excelência técnica.
          </div>
          <div class="home-text Exibition-Text text-light-small-extrav2 ">
            <span>&#10003;</span> Atendimento humanizado realizado pela nossa equipe multidisciplinar composta por
            enfermeira, psicóloga e nutricionista para cuidar de você antes, durante e depois da cirurgia.
          </div>
          <div class="home-text Exibition-Text text-light-small-extrav2 ">
            <span>&#10003;</span> Oferecemos opções personalizadas para o seu investimento.
          </div>

          <div class="nav-btn">
            <a href="https://api.whatsapp.com/send/?phone=13981911233&amp;text=Ol%C3%A1%2C+gostaria+de+marcar+uma+consulta.">
              AGENDAR CONSULTA
            </a>
          </div>
        </div>

        <div class="exibition-content2 ">
          <!-- <br /> -->
          <img
          class="border image-exibiton-center"
          alt="Dr. Antônio"
          src="images/ANTONIO PLASTICA-411.jpg"
          />
          <!-- <br /> -->
        </div>

        <div class="exibition-content2-v2 border">
          <div class="home-heading text-dark-small-extrav2">
            Tem riscos?
          </div>
          <div class="home-text Exibition-Text text-light-small-extrav2">
            A sua segurança sempre está em primeiro lugar. Desde a primeira
            consulta avaliamos cada caso, as suas indicações e traçamos um
            planejamento para as suas cirurgias. Estamos constantemente 
            nos atualizando nas novas técnicas mundiais, somando se a isso 
            sempre realizamos os procedimentos em Hospitais referência e 
            excelência em qualidade e segurança.
          </div>
          <div class="nav-btn">
            <a href="https://api.whatsapp.com/send/?phone=13981911233&amp;text=Ol%C3%A1%2C+gostaria+de+marcar+uma+consulta.">
              AGENDAR CONSULTA
            </a>
          </div>

          <div class="br">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>

        <div class="exibition-content3">
          <!-- <br /> -->
          <img
          class="border image-exibiton-center2"
          alt="Dr. Antônio"
          src="images/ANTONIO PLASTICA-411.jpg"
          />
          <!-- <br /> -->
        </div>
          
      </div>

    </div>
  </div>
</template>

<script>
  import ExibitionCard from './ExibitionCard.vue';
  import ExibitionCard2 from './ExibitionCard2.vue';

  export default {
      name: "Exibition",
      components: {
        ExibitionCard,
        ExibitionCard2
      },
      data() {
        return {
          rawvpwc: ' ',
          rawm3gm: ' ',
          rawyzqr: ' ',
        }
      },
  }
</script>

<style scoped>

  .nav-btn {
    color: var(--dl-color-seguranca);
    border-radius: var(--dl-radius-radius-radius24);
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-unit);

    background-color: var(--dl-color-responsabilidade);

    cursor: pointer;

    font-size: 18px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
  }

  .exibition-features {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .exibition-content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-tripleunit);
  }

  .exibition-content2-v2 {
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-doubleunit);

    background-color: var(--dl-color-elegancia);
  }

  .exibition-content2 {
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }

  .home-heading {
    text-align: center;
    margin-bottom: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    padding-left: var(--dl-space-space-fiveunits);
  }

  .Exibition-Text {
    font-size: 16px;
    font-family: Raleway;
    font-weight: 400;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }

  .home-text {
    width: 100%;

    text-align: left;
    margin-bottom: var(--dl-space-space-fourunits);

    padding: var(--dl-space-space-halfunit);
  }

  .image-exibiton-center {
    width: 100%;
    height: auto;
  }

  .border {
    border-radius: var(--dl-radius-radius-radius8);
  }

  .container-bg-dark {
    background-color:var(--dl-color-gray-900);
    background-size: cover;
  }

  .text-light-small-extrav2 {
    color: var(--dl-color-seguranca);
    font-weight: 700;
  }

  .text-dark-small-extrav2 {
    color: var(--dl-color-responsabilidade);
    font-weight: 999;
  }

  .exibition-content3 {
    display: none;
  }

  @media (max-width: 1200px) {
    .exibition-content {
      flex-direction: column;
      width: 100%;
    }

    .image-exibiton-center {
      display: none;
    }

    .exibition-content3 {
      width: 70%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      
      padding-top: var(--dl-space-space-doubleunit);
      padding-left: var(--dl-space-space-tripleunit);
      padding-right: var(--dl-space-space-tripleunit);
      padding-bottom: var(--dl-space-space-doubleunit);
    }

    .exibition-content2 {
      width: 100%;
    }

    .image-exibiton-center2 {
      width: 100%;
      height: auto;
    }

    .br {
      display: none;
    }

    .exibition-content2-v2 {
      width: 100%;
    }

  }

  @media (max-width: 760px) {
  }

</style>
<template>
    <div class="carousel-TO-main-container">
        <div class="carousel-container2">
            <div class="carousel-inner2">
                <input class="carousel-open2" type="radio" id="carousel-12" name="carousel2" aria-hidden="true" hidden="" checked="checked">
                <div class="carousel-item2">
                    <!-- <img src="http://fakeimg.pl/2000x800/0079D8/fff/?text=Without"> -->
                    <img
                        alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
                        src="images/Mastopexia-com-Proteses-de-Silicone-526x226px.jpg"
                        height="800"
                        width="2000"
                    />
                </div>
                <input class="carousel-open2" type="radio" id="carousel-22" name="carousel2" aria-hidden="true" hidden="">
                <div class="carousel-item2">
                    <!-- <img src="http://fakeimg.pl/2000x800/DA5930/fff/?text=JavaScript"> -->
                    <img
                        alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
                        src="images/BodyTite-526x226px.jpg"
                        height="800"
                        width="2000"
                    />
                </div>
                <input class="carousel-open2" type="radio" id="carousel-32" name="carousel2" aria-hidden="true" hidden="">
                <div class="carousel-item2">
                    <!-- <img src="http://fakeimg.pl/2000x800/F90/fff/?text=Carousel"> -->
                    <img
                        alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
                        src="images/Lipoabdominoplastia-LMD - -Abdominoplastia-526x226px.jpg"
                        height="800"
                        width="2000"
                    />
                </div>
                <label for="carousel-32" class="carousel-control2 prev2 control-12">‹</label>
                <label for="carousel-22" class="carousel-control2 next2 control-12">›</label>
                <label for="carousel-12" class="carousel-control2 prev2 control-22">‹</label>
                <label for="carousel-32" class="carousel-control2 next2 control-22">›</label>
                <label for="carousel-22" class="carousel-control2 prev2 control-32">‹</label>
                <label for="carousel-12" class="carousel-control2 next2 control-32">›</label>
                <ol class="carousel-indicators2">
                    <li>
                        <label for="carousel-12" class="carousel-bullet2">•</label>
                    </li>
                    <li>
                        <label for="carousel-22" class="carousel-bullet2">•</label>
                    </li>
                    <li>
                        <label for="carousel-32" class="carousel-bullet2">•</label>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CarouselLocaisAtendimentoTO",
    data() {
        return {
            rawvpwc: ' ',
            rawm3gm: ' ',
            rawyzqr: ' ',
        }
    },
}
</script>

<style scoped>

    .carousel-TO-main-container {
        margin: 0px;
        padding: 0px;
        /* background: url("http://digital.bnint.com/filelib/s9/photos/white_wood_4500x3000_lo_res.jpg"); */
    }

    .carousel-container2 {
        position: relative;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64);
        /* margin-top: 26px; */
    }

    .carousel-inner2 {
        position: relative;
        overflow: hidden;
        width: 100%;
    }

    .carousel-open2:checked + .carousel-item2 {
        position: static;
        opacity: 100;
    }

    .carousel-item2 {
        position: absolute;
        opacity: 0;
        -webkit-transition: opacity 0.6s ease-out;
        transition: opacity 0.6s ease-out;
    }

    .carousel-item2 img {
        display: block;
        height: auto;
        max-width: 100%;
    }

    .carousel-control2 {
        background: rgba(0, 0, 0, 0.28);
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
        display: none;
        font-size: 40px;
        height: 40px;
        line-height: 35px;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        cursor: pointer;
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        text-align: center;
        width: 40px;
        z-index: 10;
    }

    .carousel-control2.prev2 {
        left: 2%;
    }

    .carousel-control2.next2 {
        right: 2%;
    }

    .carousel-control2:hover {
        background: rgba(0, 0, 0, 0.8);
        color: #aaaaaa;
    }

    #carousel-12:checked ~ .control-12,
    #carousel-22:checked ~ .control-22,
    #carousel-32:checked ~ .control-32 {
        display: block;
    }

    .carousel-indicators2 {
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: 2%;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 10;
    }

    .carousel-indicators2 li {
        display: inline-block;
        margin: 0 5px;
    }

    .carousel-bullet2 {
        color: #fff;
        cursor: pointer;
        display: block;
        font-size: 35px;
    }

    .carousel-bullet2:hover {
        color: #aaaaaa;
    }

    #carousel-12:checked ~ .control-12 ~ .carousel-indicators2 li:nth-child(1) .carousel-bullet2,
    #carousel-22:checked ~ .control-22 ~ .carousel-indicators2 li:nth-child(2) .carousel-bullet2,
    #carousel-32:checked ~ .control-32 ~ .carousel-indicators2 li:nth-child(3) .carousel-bullet2 {
        color: #428bca;
    }

    #title {
        width: 100%;
        position: absolute;
        padding: 0px;
        margin: 0px auto;
        text-align: center;
        font-size: 27px;
        color: rgba(255, 255, 255, 1);
        font-family: 'Open Sans', sans-serif;
        z-index: 9999;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33), -1px 0px 2px rgba(255, 255, 255, 0);
    }

</style>
<template>
  <div id="depoimentos" class="home-features container-bg-light">
    <div class="home-hero-text3">
      <!-- <h2 class="home-pre-heading-v2 text-light-small-extra-v81">
        COMO EU SOU VISTO
      </h2> -->
      <h2 class="home-pre-heading-v2 text-light-small-extra-v81">
        O QUE MEUS PACIENTES FALAM
      </h2>
      <h2 class="home-heading-v2 Section-Heading-v2 text-dark-small-extra-v7">
        A qualidade e segurança do meu trabalho foi comprovada pela recorrência e indicações
      </h2>
    </div>
    <CarouselDepoimentos />
    <!-- <div class="home-cards-container3-v2">
      
         CARROSSEL -->
  <!--
          <div class="feedback-card">
            <div class="stars">
              <h3 class="text-dark-small-extra-v7">Eliete Moura</h3>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <p class="text-light-small-extra-v7"> Já faz 18 anos desde que operei com o Dr. Antonio, e minhas mamas ainda estão perfeitas. Estou ansiosa para o próximo ano, quando será a vez da minha barriga! Dr. Antônio é um cirurgião incrível.</p>
              <h4 class="text-dark-small-extra-v7">Goiânia - GO</h4>
            </div>
          </div>

          <div class="feedback-card">
            <div class="stars">
              <h3 class="text-dark-small-extra-v7">Aline Araujo</h3>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <p class="text-light-small-extra-v7">Dr. Antônio é o melhor cirurgião do mundo! Estou extremamente satisfeita com os resultados da minha cirurgia. Só posso expressar minha gratidão por ele.</p>
              <h4 class="text-dark-small-extra-v7">Goiânia - GO</h4>
            </div>
          </div>

          <div class="feedback-card">
            <div class="stars">
              <h3 class="text-dark-small-extra-v7">Lally Barreto</h3>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <p class="text-light-small-extra-v7">Dr. Antônio é um cirurgião excelente. O resultado da minha cirurgia é perfeito, e sua atenção total faz toda a diferença. Muito obrigada!</p>
              <h4 class="text-dark-small-extra-v7">Goiânia - GO</h4>
            </div>
          </div>

          <div class="feedback-card">
            <div class="stars">
              <h3 class="text-dark-small-extra-v7">Calliandra Eliel</h3>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <p class="text-light-small-extra-v7">Realizei uma cirurgia com o Dr. Antônio e amei o resultado. Mesmo após alguns anos, meus seios continuam lindos. Um profissional excepcional!</p>
              <h4 class="text-dark-small-extra-v7">Goiânia - GO</h4>
            </div>
          </div>

          <div class="feedback-card">
            <div class="stars">
              <h3 class="text-dark-small-extra-v7">Karoline Oliveira</h3>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <p class="text-light-small-extra-v7">Dr. Antônio, você realizou meu sonho e me deixou confiante o suficiente para usar um biquíni na praia pela primeira vez. Muito obrigada!</p>
              <h4 class="text-dark-small-extra-v7">Palmas - TO</h4>
            </div>
          </div>

          <div class="feedback-card">
            <div class="stars">
              <h3 class="text-dark-small-extra-v7">Euripedes Santos</h3>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <p class="text-light-small-extra-v7">Após muitos anos de espera, finalmente consegui usar o decote dos meus sonhos. Estou extremamente feliz, Dr. Antônio é incrível!</p>
              <h4 class="text-dark-small-extra-v7">Palmas - TO</h4>
            </div>
          </div>

          <div class="feedback-card">
            <div class="stars">
              <h3 class="text-dark-small-extra-v7">Gabriela Vieira</h3>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <p class="text-light-small-extra-v7">Não tenho palavras para expressar minha gratidão por essas mãos de anjo, Dr. Antônio. Esperei tanto tempo por este momento e estou encantada com os resultados.</p>
              <h4 class="text-dark-small-extra-v7">Palmas - TO</h4>
            </div>
          </div>

          <div class="feedback-card">
            <div class="stars">
              <h3 class="text-dark-small-extra-v7">Jaqueline Barros</h3>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <p class="text-light-small-extra-v7">Dr. Antônio é o melhor de todos. Já fiz duas cirurgias com ele, e no próximo ano, estou pronta para mais. Estou ansiosa para continuar minha jornada de transformação com ele.</p>
              <h4 class="text-dark-small-extra-v7">Palmas - TO</h4>
            </div>
          </div>

    </div> -->
  </div>
</template>

<script>
  import CarouselDepoimentos from './CarouselDepoimentos.vue';

  export default {
      name: "Depoimentos",
      components: {
        CarouselDepoimentos
      },
      data() {
        return {
          rawvpwc: ' ',
          rawm3gm: ' ',
          rawyzqr: ' ',
        }
      },
  }
</script>

<style scoped>
  /* linha: 183 */
  .Section-Heading-v2 {
    font-size: 48px;
    font-family: Montserrat;
    font-weight: 750;
    line-height: 1.22;
    text-transform: none;
    text-decoration: none;
    align-content: left;
    width: 100%;
    text-align: justify;
    text-justify: inter-word;
  }

  /* linha: 269 */
  .home-hero-text3 {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  /* linha: 283 */
  .home-heading-v2
  .home-pre-heading-v2 {
    width: 50%;
    display: flex;
    text-align: center;
    margin-bottom: var(--dl-space-space-fiveunits);
    justify-content: center;
    font-size: 27px;
  }

  /* linha: 320 */
  .home-features {
    width: 100%;
    display: flex;
    /* max-width: var(--dl-size-size-maxcontent); */
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
    /* margin-bottom: var(--dl-space-space-fiveunits); */
    background-color:var(--dl-color-gray-1100);
    background-size: cover;
  }

  /* linha: 384 */
  .container-bg-light {
    background-color:var(--dl-color-gray-1100);
    background-size: cover;
  }

  /* linha: 995 */
  .home-cards-container3-v2 {
    width: 70%;
    display: flex;
    margin-top: var(--dl-space-space-doubleunit);
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }

  .text-light-small-extra-v7 {
    color: var(--dl-color-seriedade);
    font-weight: 700;
  }

  .text-light-small-extra-v8 {
    color: var(--dl-color-seriedade);
    font-weight: 800;
  }

  .text-light-small-extra-v81 {
    color: var(--dl-color-leveza);
    font-weight: 750;
    font-size: 27px;
  }

  .text-dark-small-extra-v7 {
    color: var(--dl-color-responsabilidade);
    align-content: center;
    /* font-weight: 999; */
  }

  h2 {
    font-size: 48px;
  }

</style>
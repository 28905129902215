import { render, staticRenderFns } from "./ExibitionCard.vue?vue&type=template&id=1f740e96&scoped=true&"
import script from "./ExibitionCard.vue?vue&type=script&lang=js&"
export * from "./ExibitionCard.vue?vue&type=script&lang=js&"
import style0 from "./ExibitionCard.vue?vue&type=style&index=0&id=1f740e96&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f740e96",
  null
  
)

export default component.exports
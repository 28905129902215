<template>
    <div class="carousel-depoimentos">
        <div class="carousel-container3">
            <div class="carousel-inner3">
                <input class="carousel-open3" type="radio" id="carousel-13" name="carousel3" aria-hidden="true" hidden="" checked="checked">
                <div class="carousel-item3">
                    <img
                        alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
                        src="images/locais_de_atendimento/local_atendimento_GO_1.JPG"
                        height="auto"
                        width="730"
                    />
                </div>
                <input class="carousel-open3" type="radio" id="carousel-23" name="carousel3" aria-hidden="true" hidden="">
                <div class="carousel-item3">
                    <img
                        alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
                        src="images/locais_de_atendimento/local_atendimento_GO_2.jpg"
                        height="auto"
                        width="730"
                    />
                </div>
                <input class="carousel-open3" type="radio" id="carousel-33" name="carousel3" aria-hidden="true" hidden="">
                <div class="carousel-item3">
                    <img
                        alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
                        src="images/locais_de_atendimento/local_atendimento_GO_3.JPG"
                        height="auto"
                        width="730"
                    />
                </div>
                <input class="carousel-open3" type="radio" id="carousel-43" name="carousel3" aria-hidden="true" hidden="">
                <div class="carousel-item3">
                    <img
                        alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
                        src="images/locais_de_atendimento/local_atendimento_TO_1.jpg"
                        height="auto"
                        width="730"
                    />
                </div>
                <input class="carousel-open3" type="radio" id="carousel-53" name="carousel3" aria-hidden="true" hidden="">
                <div class="carousel-item3">
                    <img
                        alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
                        src="images/locais_de_atendimento/local_atendimento_TO_2.jpg"
                        height="auto"
                        width="730"
                    />
                </div>
                <input class="carousel-open3" type="radio" id="carousel-63" name="carousel3" aria-hidden="true" hidden="">
                <div class="carousel-item3">
                    <img
                        alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
                        src="images/locais_de_atendimento/local_atendimento_TO_3.jpg"
                        height="auto"
                        width="730"
                    />
                </div>
                <input class="carousel-open3" type="radio" id="carousel-73" name="carousel3" aria-hidden="true" hidden="">
                <div class="carousel-item3">
                    <img
                        alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
                        src="images/locais_de_atendimento/local_atendimento_TO_4.jpg"
                        height="auto"
                        width="730"
                    />
                </div>
                <label for="carousel-73" class="carousel-control3 prev3 control-13">‹</label>
                <label for="carousel-23" class="carousel-control3 next3 control-13">›</label>
                <label for="carousel-13" class="carousel-control3 prev3 control-23">‹</label>
                <label for="carousel-33" class="carousel-control3 next3 control-23">›</label>
                <label for="carousel-23" class="carousel-control3 prev3 control-33">‹</label>
                <label for="carousel-43" class="carousel-control3 next3 control-33">›</label>
                <label for="carousel-33" class="carousel-control3 prev3 control-43">‹</label>
                <label for="carousel-53" class="carousel-control3 next3 control-43">›</label>
                <label for="carousel-43" class="carousel-control3 prev3 control-53">‹</label>
                <label for="carousel-63" class="carousel-control3 next3 control-53">›</label>
                <label for="carousel-53" class="carousel-control3 prev3 control-63">‹</label>
                <label for="carousel-73" class="carousel-control3 next3 control-63">›</label>
                <label for="carousel-63" class="carousel-control3 prev3 control-73">‹</label>
                <label for="carousel-13" class="carousel-control3 next3 control-73">›</label>
                <ol class="carousel-indicators3">
                    <li>
                        <label for="carousel-13" class="carousel-bullet3">•</label>
                    </li>
                    <li>
                        <label for="carousel-23" class="carousel-bullet3">•</label>
                    </li>
                    <li>
                        <label for="carousel-33" class="carousel-bullet3">•</label>
                    </li>
                    <li>
                        <label for="carousel-43" class="carousel-bullet3">•</label>
                    </li>
                    <li>
                        <label for="carousel-53" class="carousel-bullet3">•</label>
                    </li>
                    <li>
                        <label for="carousel-63" class="carousel-bullet3">•</label>
                    </li>
                    <li>
                        <label for="carousel-73" class="carousel-bullet3">•</label>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LocaisDeCirurgia",
    data() {
        return {
            rawvpwc: ' ',
            rawm3gm: ' ',
            rawyzqr: ' ',
        }
    },
}
</script>

<style scoped>

    .carousel-depoimentos {
        margin: 0px;
        padding: 0px;
        /* background: url("http://digital.bnint.com/filelib/s9/photos/white_wood_4500x3000_lo_res.jpg"); */
        width: 50%;
        display: flex;
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: column;

    }

    .carousel-container3 {
        position: relative;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64);
        margin-top: 26px;
    }

    .carousel-inner3 {
        position: relative;
        overflow: hidden;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .carousel-open3:checked + .carousel-item3 {
        position: static;
        opacity: 100;
        /* width: 80%; */
    }

    .carousel-item3 {
        position: absolute;
        opacity: 0;
        -webkit-transition: opacity 0.6s ease-out;
        transition: opacity 0.6s ease-out;
        /* width: 80%; */
    }

    .carousel-item3 img {
        display: block;
        height: auto;
        /* max-width: 100%; */
    }

    .carousel-control3 {
        background: rgba(0, 0, 0, 0.28);
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
        display: none;
        font-size: 40px;
        height: 40px;
        line-height: 35px;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        cursor: pointer;
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        text-align: center;
        width: 40px;
        z-index: 10;
    }

    .carousel-control3.prev3 {
        left: 2%;
    }

    .carousel-control3.next3 {
        right: 2%;
    }

    .carousel-control3:hover {
        background: rgba(0, 0, 0, 0.8);
        color: #aaaaaa;
    }

    #carousel-13:checked ~ .control-13,
    #carousel-23:checked ~ .control-23,
    #carousel-33:checked ~ .control-33,
    #carousel-43:checked ~ .control-43,
    #carousel-53:checked ~ .control-53,
    #carousel-63:checked ~ .control-63,
    #carousel-73:checked ~ .control-73 {
        display: block;
    }

    .carousel-indicators3 {
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: 2%;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 10;
    }

    .carousel-indicators3 li {
        display: inline-block;
        margin: 0 5px;
    }

    .carousel-bullet3 {
        color: #fff;
        cursor: pointer;
        display: block;
        font-size: 35px;
    }

    .carousel-bullet3:hover {
        color: #aaaaaa;
    }

    #carousel-13:checked ~ .control-13 ~ .carousel-indicators3 li:nth-child(1) .carousel-bullet3,
    #carousel-23:checked ~ .control-23 ~ .carousel-indicators3 li:nth-child(2) .carousel-bullet3,
    #carousel-33:checked ~ .control-33 ~ .carousel-indicators3 li:nth-child(3) .carousel-bullet3,
    #carousel-43:checked ~ .control-43 ~ .carousel-indicators3 li:nth-child(4) .carousel-bullet3,
    #carousel-53:checked ~ .control-53 ~ .carousel-indicators3 li:nth-child(5) .carousel-bullet3,
    #carousel-63:checked ~ .control-63 ~ .carousel-indicators3 li:nth-child(6) .carousel-bullet3,
    #carousel-73:checked ~ .control-73 ~ .carousel-indicators3 li:nth-child(7) .carousel-bullet3 {
        color: #428bca;
    }

    #title {
        width: 100%;
        position: absolute;
        padding: 0px;
        margin: 0px auto;
        text-align: center;
        font-size: 27px;
        color: rgba(255, 255, 255, 1);
        font-family: 'Open Sans', sans-serif;
        z-index: 9999;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33), -1px 0px 2px rgba(255, 255, 255, 0);
    }

    .feedback-card {
        /* background-color: #f8f8f8; */
        background-color: var(--dl-color-seguranca);
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: 20px;
        /* max-width: 400px; */
        margin: 20px auto;

        display: flex;
        /* align-items: center; */
        padding-top: var(--dl-space-space-tripleunit);
        padding-left: var(--dl-space-space-doubleunit);
        padding-right: var(--dl-space-space-doubleunit);
        flex-direction: column;
        padding-bottom: var(--dl-space-space-fiveunits);
        justify-content: center;
        align-content: center;
        justify-items: center;
        align-items: center;
        width: 100%;
        /* height: auto; */
        height: 100%;
        margin: 1%;
    }

    .stars {
        display: inline-block;
        vertical-align: middle;
    }

    .stars i {
        color: #ffcc00;
    }

    .text-light-small-extra-v7 {
        color: var(--dl-color-seriedade);
        font-weight: 700;
    }

    .text-dark-small-extra-v7 {
        color: var(--dl-color-responsabilidade);
        align-content: center;
        /* font-weight: 999; */
    }

    @media (max-width: 1230px) {
        .carousel-depoimentos{
            width: 90%;
        }
    }
    @media (max-width: 1060px) {
        .carousel-depoimentos {
            margin: 0px;
            padding: 0px;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }

    @media (max-width: 760px) {
    }

</style>
<template>
    <div class="home-heading-container1">
        
        <div class="tooltip home-cards-container1-v2">  
            <input type="checkbox" value="selected" id="FAQ-question-1" class="tooltip-input">
            <label for="FAQ-question-1" class="tooltip-label">
                <h3 class="home-text10 Card-Heading">
                    Qual é o período médio de recuperação após uma cirurgia plástica? 
                </h3>
            </label> 
            <div role="tooltip" id="tooltipID" class="tooltip-content">
                <span class="home-text10 Card-Text cor_do_texto">
                    O período de recuperação após uma cirurgia plástica varia conforme os procedimentos e o
                    organismo da paciente. Geralmente, pode levar de algumas semanas ha vários meses. 
                    Porém para que os resultados finais sejam visíveis, levará em média de 9 a 12 meses ou mais.
                </span>
            </div>
        </div>

        <div class="tooltip home-cards-container1-v2">  
            <input type="checkbox" value="selected" id="FAQ-question-2" class="tooltip-input">
            <label for="FAQ-question-2" class="tooltip-label">
                <h3 class="home-text10 Card-Heading">
                    É comum ter inchaço após a realização de um procedimento de cirurgia plástica?
                </h3>
            </label>
            <div role="tooltip" id="tooltipID" class="tooltip-content">
                <span class="home-text10 Card-Text cor_do_texto">
                    Sim, é comum ter inchaço após uma cirurgia plástica. O inchaço é uma resposta natural do corpo à
                    intervenção cirúrgica e geralmente diminui ao longo do processo de recuperação, podendo levar 
                    semanas ou meses para desaparecer completamente, dependendo do procedimento.
                </span>
            </div>
        </div>

        <div class="tooltip home-cards-container1-v2">  
            <input type="checkbox" value="selected" id="FAQ-question-3" class="tooltip-input">
            <label for="FAQ-question-3" class="tooltip-label">
                <h3 class="home-text10 Card-Heading">
                    Em quais casos é indicada a utilização de cinta ou sutiã pós-operatório? Qual é a sua importância?
                </h3>
            </label>
            <div role="tooltip" id="tooltipID" class="tooltip-content">
                <span class="home-text10 Card-Text cor_do_texto">
                    A cinta pós-operatória é recomendada em procedimentos como lipoaspiração e abdominoplastia. 
                    Ela ajuda a reduzir o inchaço, promove a cicatrização adequada, e proporciona suporte, contribuindo
                    para resultados mais satisfatórios e uma recuperação mais confortável. O sutiã pós-operatório 
                    é indicado nas cirurgias mamárias como proteses, mamoplastia redutora ou mastopexia. Ele tem 
                    a função de sustentar as mamas, moldando as até que ocorra a completa cicatrização.
                </span>
            </div>
        </div>

        <div class="tooltip home-cards-container1-v2">  
            <input type="checkbox" value="selected" id="FAQ-question-4" class="tooltip-input">
            <label for="FAQ-question-4" class="tooltip-label">
                <h3 class="home-text10 Card-Heading">
                    Quais são as perguntas essenciais a serem feitas durante a primeira consulta, sobre o procedimento indicado para o meu caso?
                </h3>
            </label>
            <div role="tooltip" id="tooltipID" class="tooltip-content">
                <span class="home-text10 Card-Text cor_do_texto">
                    Durante a primeira consulta, é fundamental discutir suas expectativas, e quais os possíveis
                    resultados alcançáveis. Analisar sua  anatômia individual, seus antecedentes médicos, cirurgias
                    anteriores e medicamentos que está fazendo o uso, com base nessas informações, poderemos
                    indicar o procedimento mais adequado para você.
                </span>
            </div>
        </div>

        <div class="tooltip home-cards-container1-v2">  
            <input type="checkbox" value="selected" id="FAQ-question-5" class="tooltip-input">
            <label for="FAQ-question-5" class="tooltip-label">
                <h3 class="home-text10 Card-Heading">
                    Quais são os cuidados necessários durante o período de recuperação após a cirurgia plástica?
                </h3>
            </label>
            <div role="tooltip" id="tooltipID" class="tooltip-content">
                <span class="home-text10 Card-Text cor_do_texto">
                    Durante a recuperação pós-cirurgia plástica, é vital seguir as nossas orientações, incluindo 
                    repouso, uso de medicamentos prescritos, evitar esforços e manter uma dieta saudável. O 
                    acompanhamento com nossa equipe multidisciplinar, também fará você ter uma recuperação bem-sucedida.
                    
                    <b>
                        Atualmente também temos indicado sessões de câmara hiperbárica (oxigênio terápia) para 
                        melhorar a cicatrização e a recuperação dos tecidos.
                    </b>
                </span>
            </div>
        </div>

        <div class="tooltip home-cards-container1-v2">  
            <input type="checkbox" value="selected" id="FAQ-question-6" class="tooltip-input">
            <label for="FAQ-question-6" class="tooltip-label">
                <h3 class="home-text10 Card-Heading">
                    Quanto tempo leva para ver os resultados finais do procedimento de cirurgia plástica?
                </h3>
            </label>
            <div role="tooltip" id="tooltipID" class="tooltip-content">
                <span class="home-text10 Card-Text cor_do_texto">
                    O tempo para ver os resultados finais varia de acordo com o tipo de cirurgia plástica, com o
                    organismo da paciente, mas geralmente leva em média de 9 a 12 meses para que os resultados
                    se estabilizem completamente. O acompanhamento médico é importante para monitorar o progresso.
                </span>
            </div>
        </div>

        <div class="tooltip home-cards-container1-v2">  
            <input type="checkbox" value="selected" id="FAQ-question-7" class="tooltip-input">
            <label for="FAQ-question-7" class="tooltip-label">
                <h3 class="home-text10 Card-Heading">
                    Existem riscos associados ao inchaço prolongado após a cirurgia plástica? Como eles são tratados?
                </h3>
            </label>
            <div role="tooltip" id="tooltipID" class="tooltip-content">
                <span class="home-text10 Card-Text cor_do_texto">
                    O inchaço prolongado pós-cirurgia plástica pode indicar intercorrências como acúmulo de
                    líquido (seroma ou hematoma). Caso acontecer você deverá comunicar imediatamente a nossa
                    equipe. O tratamento pode envolver drenagem, medicamentos ou procedimentos específicos 
                    para resolver a causa subjacente.
                </span>
            </div>
        </div>

        <div class="tooltip home-cards-container1-v2">  
            <input type="checkbox" value="selected" id="FAQ-question-8" class="tooltip-input">
            <label for="FAQ-question-8" class="tooltip-label">
                <h3 class="home-text10 Card-Heading">
                    Qual a importância da consulta pré-operatória com um cirurgião plástico para esclarecer dúvidas sobre o procedimento desejado?
                </h3>
            </label>
            <div role="tooltip" id="tooltipID" class="tooltip-content">
                <span class="home-text10 Card-Text cor_do_texto">
                    A primeira consulta é o momento mais importante para realmente avaliar O seu caso, decidirmos qual
                    o melhor tratamento, esclarecer dúvidas, discutir riscos, avaliar a expectativa dos resultados
                    desejáveis X reais (atingíveis), criando um plano personalizado e seguro.
                </span>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "FAQToogle",
    data() {
        return {
            rawvpwc: ' ',
            rawm3gm: ' ',
            rawyzqr: ' ',
        }
    },
}
</script>

<style scoped>
    .cor_do_texto {
        color: var(--dl-color-responsabilidade);
        font-weight: 700;
    }
    .tooltip-label {
        font-size: 22px;
        font-family: 'Helvetica' serif;
        background: #575434;
        padding: 0.4em;
        cursor: pointer;
        display: inline-block;
        margin-bottom: 1em;
        color: var(--dl-color-maturidade);
    }
    .tooltip-input {
        display: none;
    } /*Hide input element*/

    .tooltip-content {
        margin-bottom: 1.5em;
        background-color: var(--dl-color-maturidade);
    }
    .tooltip-input:not(checked) ~ .tooltip-content {
        display: none;
    }
    .tooltip-input:checked ~ .tooltip-content {       
        display: block;
    }

    .home-cards-container1-v2 {
        width: 100%;
        display: flex;
        /* max-width: var(--dl-size-size-maxcontent); */
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
    }

    .tooltip {
        width: 100%;
    }

    .tooltip-label {
        width: 100%;
        margin-bottom: 0;
        margin-top: 22px;
    }

    .Card-Heading {
        font-size: 17.5px;
        font-weight: 700;
    }

    h3 {
        margin-bottom: 0;
    }

    .home-text10 {
        text-align: left;
    }

</style>
<template>
  <div id="hospital-services-visualization" class="home-features container-bg-light">
    <div class="home-hero-text3">
      <h2 class="home-pre-heading-v2 text-light-small-extra-v31">
        Conheça meus locais de atendimentos
      </h2>
      <h2 class="home-heading-v2 Section-Heading-v2 text-dark-small-extra-v3">
        ENCONTRE O MAIS PERTO DE VOCÊ
      </h2>
    </div>
    <div class="hospital-services-content">
      <div class="hospital_services_cards_containers">
        <div class="home-service-card1">
          <h3 class="home-text12 Card-Heading text-dark-small-extra-v3">GOIÂNIA HOSPITAL UNIQUE</h3>
          <h3 class="home-text12 Card-Heading text-dark-small-extra-v3">Goiânia/GO</h3>
          <div class="container-card-especialidades-left2">
            <img
              alt="Morpheus"
              src="images/hospital_unique.png"
              class="img-especialidades"
              height="226"
              width="526"
            />
          </div>          

        </div>
        <div class="home-service-card1">
          <h3 class="home-text12 Card-Heading text-dark-small-extra-v3">PRIME CLINICA</h3>
          <h3 class="home-text12 Card-Heading text-dark-small-extra-v3">Palmas/TO</h3>
          <div class="container-card-especialidades-left2">
            <img
              alt="Morpheus"
              src="images/prime_clinica.png"
              class="img-especialidades"
              height="226"
              width="526"
            />
          </div>          

        </div>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
      name: "HospitalServicesVisualization",
      data() {
        return {
          rawvpwc: ' ',
          rawm3gm: ' ',
          rawyzqr: ' ',
        }
      },
  }
</script>

<style scoped>

  /* linha: 183 */
  .Section-Heading-v2 {
    font-size: 48px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1.22;
    text-transform: none;
    text-decoration: none;
    text-align: center;
    width: 100%;
    text-align: justify;
    text-justify: inter-word;
  }

  /* linha: 320 */
  .home-features {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    padding-top: var(--dl-space-space-hundredpx);
    padding-bottom: var(--dl-space-space-hundredpx);
    padding-left: 0;
    padding-right: 0;
  }

  .hospital-services-content {
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-tenunits);
  }

  /* linha: 696 */
  .home-service-card {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-tripleunit);
    align-items: center;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-maturidade);
  }

  /* linha: 726 */
  .home-service-card1 {
    width: 68%;
    display: flex;
    padding: var(--dl-space-space-tripleunit);

    align-items: center;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-maturidade);
  }

  /* linha: 736 */
  .home-text12 {
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }

  .hospital_services_cards_containers {
    width: 100%;
    display: flex;
    margin-top: 0;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .container-card-especialidades-left2 {
    width: 48%;
    height: 100%;
    display: flex;
    align-items: center;
    /* border-color: var(--dl-color-gray-900); */
    border-color: var(--dl-color-responsabilidade);
    background-color: var(--dl-color-seguranca);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: center;
    margin-right: 2%;
    padding: 18px;
    margin-top: 18px;
  }

  .img-especialidades {
    width: 100%;
    height: auto;
    border-radius: var(--dl-radius-radius-radius4);
  }

  .container-bg-light {
    background-color:var(--dl-color-gray-1100);
    background-size: cover;
  }

  .home-hero-text3 {
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .home-heading-v2
  .home-pre-heading-v2 {
    width: 50%;
    text-align: center;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  
  .text-dark-small-extra-v3 {
    color: var(--dl-color-responsabilidade);
    margin-bottom: var(--dl-space-space-doubleunit);
    /* font-weight: 999; */
  }

  .text-light-small-extra-v31 {
    color: var(--dl-color-leveza);
    /* font-weight: 700; */
  }

  @media (max-width: 1200px) {
    .home-service-card1 {
      width: 100%;
    }

    .hospital-services-content {
      width: 100%;
    }
  }

  @media (max-width: 760px) {
  }

</style>
<template>
    <div class="carousel-depoimentos">
        <!-- <div class="carousel-container3"> -->
            <!-- <div class="carousel-inner3"> -->
                <!-- <input class="carousel-open3" type="radio" id="carousel-13" name="carousel3" aria-hidden="true" hidden="" checked="checked"> -->
                <!-- <div class="carousel-item3"> -->
                    <!-- <img src="http://fakeimg.pl/2000x800/0079D8/fff/?text=Without"> -->
                    <div class="feedback-card">
                        <div class="stars">
                            <h3 class="text-dark-small-extra-v7">Eliete Moura</h3>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <p class="text-light-small-extra-v7"> Já faz 18 anos desde que operei com o Dr. Antonio, e minhas mamas ainda estão perfeitas. Estou ansiosa para o próximo ano, quando será a vez da minha barriga! Dr. Antônio é um cirurgião incrível.</p>
                            <h4 class="text-dark-small-extra-v7">Goiânia - GO</h4>
                        </div>
                    </div>

                    <div class="feedback-card">
                        <div class="stars">
                            <h3 class="text-dark-small-extra-v7">Aline Araujo</h3>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <p class="text-light-small-extra-v7">Dr. Antônio é o melhor cirurgião do mundo! Estou extremamente satisfeita com os resultados da minha cirurgia. Só posso expressar minha gratidão por ele.</p>
                            <h4 class="text-dark-small-extra-v7">Goiânia - GO</h4>
                        </div>
                    </div>

                    <div class="feedback-card">
                        <div class="stars">
                            <h3 class="text-dark-small-extra-v7">Lally Barreto</h3>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <p class="text-light-small-extra-v7">Dr. Antônio é um cirurgião excelente. O resultado da minha cirurgia é perfeito, e sua atenção total faz toda a diferença. Muito obrigada!</p>
                            <h4 class="text-dark-small-extra-v7">Goiânia - GO</h4>
                        </div>
                    </div>

                    <div class="feedback-card">
                        <div class="stars">
                            <h3 class="text-dark-small-extra-v7">Calliandra Eliel</h3>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <p class="text-light-small-extra-v7">Realizei uma cirurgia com o Dr. Antônio e amei o resultado. Mesmo após alguns anos, meus seios continuam lindos. Um profissional excepcional!</p>
                            <h4 class="text-dark-small-extra-v7">Goiânia - GO</h4>
                        </div>
                    </div>

                    <div class="feedback-card">
                        <div class="stars">
                            <h3 class="text-dark-small-extra-v7">Karoline Oliveira</h3>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <p class="text-light-small-extra-v7">Dr. Antônio, você realizou meu sonho e me deixou confiante o suficiente para usar um biquíni na praia pela primeira vez. Muito obrigada!</p>
                            <h4 class="text-dark-small-extra-v7">Palmas - TO</h4>
                        </div>
                    </div>

                    <div class="feedback-card">
                        <div class="stars">
                            <h3 class="text-dark-small-extra-v7">Euripedes Santos</h3>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <p class="text-light-small-extra-v7">Após muitos anos de espera, finalmente consegui usar o decote dos meus sonhos. Estou extremamente feliz, Dr. Antônio é incrível!</p>
                            <h4 class="text-dark-small-extra-v7">Palmas - TO</h4>
                        </div>
                    </div>

                    <div class="feedback-card">
                        <div class="stars">
                            <h3 class="text-dark-small-extra-v7">Gabriela Vieira</h3>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <p class="text-light-small-extra-v7">Não tenho palavras para expressar minha gratidão por essas mãos de anjo, Dr. Antônio. Esperei tanto tempo por este momento e estou encantada com os resultados.</p>
                            <h4 class="text-dark-small-extra-v7">Palmas - TO</h4>
                        </div>
                    </div>

                    <div class="feedback-card">
                        <div class="stars">
                            <h3 class="text-dark-small-extra-v7">Jaqueline Barros</h3>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <p class="text-light-small-extra-v7">Dr. Antônio é o melhor de todos. Já fiz duas cirurgias com ele, e no próximo ano, estou pronta para mais. Estou ansiosa para continuar minha jornada de transformação com ele.</p>
                            <h4 class="text-dark-small-extra-v7">Palmas - TO</h4>
                        </div>
                    </div>


                <!-- </div> -->
                <!-- <input class="carousel-open3" type="radio" id="carousel-23" name="carousel3" aria-hidden="true" hidden="">
                <div class="carousel-item3">
                    <img
                        alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
                        src="images/BodyTite-526x226px.jpg"
                        height="800"
                        width="2000"
                    />
                </div>
                <input class="carousel-open3" type="radio" id="carousel-33" name="carousel3" aria-hidden="true" hidden="">
                <div class="carousel-item3">
                    <div class="feedback-card">
                        <div class="stars">
                            <h3 class="text-dark-small-extra-v7">Aline Araujo</h3>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <p class="text-light-small-extra-v7">Dr. Antônio é o melhor cirurgião do mundo! Estou extremamente satisfeita com os resultados da minha cirurgia. Só posso expressar minha gratidão por ele.</p>
                            <h4 class="text-dark-small-extra-v7">Goiânia - GO</h4>
                        </div>
                    </div>
                </div> -->
                <!-- <label for="carousel-33" class="carousel-control3 prev3 control-13">‹</label>
                <label for="carousel-23" class="carousel-control3 next3 control-13">›</label>
                <label for="carousel-13" class="carousel-control3 prev3 control-23">‹</label>
                <label for="carousel-33" class="carousel-control3 next3 control-23">›</label>
                <label for="carousel-23" class="carousel-control3 prev3 control-33">‹</label>
                <label for="carousel-13" class="carousel-control3 next3 control-33">›</label>
                <ol class="carousel-indicators3">
                    <li>
                        <label for="carousel-13" class="carousel-bullet3">•</label>
                    </li>
                    <li>
                        <label for="carousel-23" class="carousel-bullet3">•</label>
                    </li>
                    <li>
                        <label for="carousel-33" class="carousel-bullet3">•</label>
                    </li>
                </ol> -->
            <!-- </div> -->
        <!-- </div> -->
    </div>
</template>

<script>
export default {
    name: "CarouselDepoimentos",
    data() {
        return {
            rawvpwc: ' ',
            rawm3gm: ' ',
            rawyzqr: ' ',
        }
    },
}
</script>

<style scoped>

    .carousel-depoimentos {
        margin: 0px;
        padding: 0px;
        /* background: url("http://digital.bnint.com/filelib/s9/photos/white_wood_4500x3000_lo_res.jpg"); */
        display: flex;

    }

    .carousel-container3 {
        position: relative;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64);
        margin-top: 26px;
    }

    .carousel-inner3 {
        position: relative;
        overflow: hidden;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .carousel-open3:checked + .carousel-item3 {
        position: static;
        opacity: 100;
    }

    .carousel-item3 {
        position: absolute;
        opacity: 0;
        -webkit-transition: opacity 0.6s ease-out;
        transition: opacity 0.6s ease-out;
    }

    .carousel-item3 img {
        display: block;
        height: auto;
        /* max-width: 100%; */
    }

    .carousel-control3 {
        background: rgba(0, 0, 0, 0.28);
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
        display: none;
        font-size: 40px;
        height: 40px;
        line-height: 35px;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        cursor: pointer;
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        text-align: center;
        width: 40px;
        z-index: 10;
    }

    .carousel-control3.prev3 {
        left: 2%;
    }

    .carousel-control3.next3 {
        right: 2%;
    }

    .carousel-control3:hover {
        background: rgba(0, 0, 0, 0.8);
        color: #aaaaaa;
    }

    #carousel-13:checked ~ .control-13,
    #carousel-23:checked ~ .control-23,
    #carousel-33:checked ~ .control-33 {
        display: block;
    }

    .carousel-indicators3 {
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: 2%;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 10;
    }

    .carousel-indicators3 li {
        display: inline-block;
        margin: 0 5px;
    }

    .carousel-bullet3 {
        color: #fff;
        cursor: pointer;
        display: block;
        font-size: 35px;
    }

    .carousel-bullet3:hover {
        color: #aaaaaa;
    }

    #carousel-13:checked ~ .control-13 ~ .carousel-indicators3 li:nth-child(1) .carousel-bullet3,
    #carousel-23:checked ~ .control-23 ~ .carousel-indicators3 li:nth-child(2) .carousel-bullet3,
    #carousel-33:checked ~ .control-33 ~ .carousel-indicators3 li:nth-child(3) .carousel-bullet3 {
        color: #428bca;
    }

    #title {
        width: 100%;
        position: absolute;
        padding: 0px;
        margin: 0px auto;
        text-align: center;
        font-size: 27px;
        color: rgba(255, 255, 255, 1);
        font-family: 'Open Sans', sans-serif;
        z-index: 9999;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33), -1px 0px 2px rgba(255, 255, 255, 0);
    }

    .feedback-card {
        /* background-color: #f8f8f8; */
        background-color: var(--dl-color-seguranca);
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: 20px;
        /* max-width: 400px; */
        margin: 20px auto;

        display: flex;
        /* align-items: center; */
        padding-top: var(--dl-space-space-tripleunit);
        padding-left: var(--dl-space-space-doubleunit);
        padding-right: var(--dl-space-space-doubleunit);
        flex-direction: column;
        padding-bottom: var(--dl-space-space-fiveunits);
        justify-content: center;
        align-content: center;
        justify-items: center;
        align-items: center;
        width: 100%;
        /* height: auto; */
        height: 100%;
        margin: 1%;
    }

    .stars {
        display: inline-block;
        vertical-align: middle;
    }

    .stars i {
        color: #ffcc00;
    }

    .text-light-small-extra-v7 {
        color: var(--dl-color-seriedade);
        font-weight: 700;
    }

    .text-dark-small-extra-v7 {
        color: var(--dl-color-responsabilidade);
        align-content: center;
        /* font-weight: 999; */
    }

    @media (max-width: 1400px) {
        .carousel-depoimentos {
            margin: 0px;
            padding: 0px;
            /* background: url("http://digital.bnint.com/filelib/s9/photos/white_wood_4500x3000_lo_res.jpg"); */
            display: flex;
            flex-direction: column;
        
        }
    }

    @media (max-width: 760px) {
    }

</style>
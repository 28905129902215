<template>
    <div class="carousel-myacting">
        <div class="carousel-container4">
            <div class="carousel-inner4">
                <input class="carousel-open4" type="radio" id="carousel-14" name="carousel4" aria-hidden="true" hidden="" checked="checked">
                <div class="carousel-item4">
                    <!-- <img src="http://fakeimg.pl/2000x800/0079D8/fff/?text=Without"> -->
                    <img
                        alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
                        src="images/Lipoabdominoplastia-LMD - -Abdominoplastia-526x226px.jpg"
                        height="800"
                        width="2000"
                    />
                </div>
                <input class="carousel-open4" type="radio" id="carousel-24" name="carousel4" aria-hidden="true" hidden="">
                <div class="carousel-item4">
                    <!-- <img src="http://fakeimg.pl/2000x800/DA5930/fff/?text=JavaScript"> -->
                    <img
                        alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
                        src="images/BodyTite-526x226px.jpg"
                        height="800"
                        width="2000"
                    />
                </div>
                <input class="carousel-open4" type="radio" id="carousel-34" name="carousel4" aria-hidden="true" hidden="">
                <div class="carousel-item4">
                    <!-- <img src="http://fakeimg.pl/2000x800/F90/fff/?text=Carousel"> -->
                    <img
                        alt="LAD, LMD (Lipo HD), Vibro-Lipoescultura"
                        src="images/Mastopexia-com-Proteses-de-Silicone-526x226px.jpg"
                        height="800"
                        width="2000"
                    />
                </div>
                <label for="carousel-34" class="carousel-control4 prev4 control-14">‹</label>
                <label for="carousel-24" class="carousel-control4 next4 control-14">›</label>
                <label for="carousel-14" class="carousel-control4 prev4 control-24">‹</label>
                <label for="carousel-34" class="carousel-control4 next4 control-24">›</label>
                <label for="carousel-24" class="carousel-control4 prev4 control-34">‹</label>
                <label for="carousel-14" class="carousel-control4 next4 control-34">›</label>
                <ol class="carousel-indicators4">
                    <li>
                        <label for="carousel-14" class="carousel-bullet4">•</label>
                    </li>
                    <li>
                        <label for="carousel-24" class="carousel-bullet4">•</label>
                    </li>
                    <li>
                        <label for="carousel-34" class="carousel-bullet4">•</label>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CarouselMyActing",
    data() {
        return {
            rawvpwc: ' ',
            rawm3gm: ' ',
            rawyzqr: ' ',
        }
    },
}
</script>

<style scoped>

    .carousel-myacting {
        margin: 0px;
        padding: 0px;
        /* background: url("http://digital.bnint.com/filelib/s9/photos/white_wood_4500x3000_lo_res.jpg"); */
    }

    .carousel-container4 {
        position: relative;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64);
        /* margin-top: 26px; */
    }

    .carousel-inner4 {
        position: relative;
        overflow: hidden;
        width: 100%;
    }

    .carousel-open4:checked + .carousel-item4 {
        position: static;
        opacity: 100;
    }

    .carousel-item4 {
        position: absolute;
        opacity: 0;
        -webkit-transition: opacity 0.6s ease-out;
        transition: opacity 0.6s ease-out;
    }

    .carousel-item4 img {
        display: block;
        height: auto;
        max-width: 100%;
    }

    .carousel-control4 {
        background: rgba(0, 0, 0, 0.28);
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
        display: none;
        font-size: 40px;
        height: 40px;
        line-height: 35px;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        cursor: pointer;
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        text-align: center;
        width: 40px;
        z-index: 10;
    }

    .carousel-control4.prev4 {
        left: 2%;
    }

    .carousel-control4.next4 {
        right: 2%;
    }

    .carousel-control4:hover {
        background: rgba(0, 0, 0, 0.8);
        color: #aaaaaa;
    }

    #carousel-14:checked ~ .control-14,
    #carousel-24:checked ~ .control-24,
    #carousel-34:checked ~ .control-34 {
        display: block;
    }

    .carousel-indicators4 {
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: 2%;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 10;
    }

    .carousel-indicators4 li {
        display: inline-block;
        margin: 0 5px;
    }

    .carousel-bullet4 {
        color: #fff;
        cursor: pointer;
        display: block;
        font-size: 35px;
    }

    .carousel-bullet4:hover {
        color: #aaaaaa;
    }

    #carousel-14:checked ~ .control-14 ~ .carousel-indicators4 li:nth-child(1) .carousel-bullet4,
    #carousel-24:checked ~ .control-24 ~ .carousel-indicators4 li:nth-child(2) .carousel-bullet4,
    #carousel-34:checked ~ .control-34 ~ .carousel-indicators4 li:nth-child(3) .carousel-bullet4 {
        color: #428bca;
    }

    #title {
        width: 100%;
        position: absolute;
        padding: 0px;
        margin: 0px auto;
        text-align: center;
        font-size: 27px;
        color: rgba(255, 255, 255, 1);
        font-family: 'Open Sans', sans-serif;
        z-index: 9999;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33), -1px 0px 2px rgba(255, 255, 255, 0);
    }

</style>
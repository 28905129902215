<template>
  <div id="counter" class="home-our-story-v2 container-bg-dark">
    <div class="home-our-story-v3">
      <div class="home-card2">
        <div class="home-card2">
          <div class="container-card-especialidades text-light-small-bg-extra-v13">
            <span>
              +15
            </span>
            <span>
              Anos de Experiência
            </span>
          </div>
        </div>
        <div class="home-card">
          <div class="container-card-especialidades2 text-light-small-bg-extra-v13">
            <span>
              +15000
            </span>
            <span>
              Pacientes atendidos
            </span>
          </div>
          <div class="container-card-especialidades2 text-light-small-bg-extra-v13">
            <span>
              +5000
            </span>
            <span>
              Cirurgias realizadas
            </span>
          </div>
        </div>
      </div>
      <div class="home-card">
        <div class="home-content-container2">
          <span class="home-text24 SmallCard-Heading">
            Minha missão é proporcionar através de uma experiência humanizada com segurança, o
            resgate da autoestima, transformando vidas.
          </span>
        </div>

        <div class="">
          <div class="nav-btn">
            <a href="https://api.whatsapp.com/send/?phone=13981911233&amp;text=Ol%C3%A1%2C+gostaria+de+marcar+uma+consulta.">
              AGENDAR CONSULTA
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- <CounterCard /> -->
  </div>
</template>

<script>
  import CounterCard from './CounterCard.vue';
  export default {
      name: "Counter",
      components: {
        CounterCard
      },
      data() {
        return {
          rawvpwc: ' ',
          rawm3gm: ' ',
          rawyzqr: ' ',
        }
      },
  }
</script>

<style scoped>
  /* linha: 103 */
  .SmallCard-Heading {
    font-size: 18px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }

  /* linha: 389 */
  .container-bg-dark {
    background-color:var(--dl-color-gray-900);
    background-size: cover;
  }

  /* linha: 956 */
  .home-our-story-v2 {
    /* width: 100%; */
    display: flex;
    /* max-width: var(--dl-size-size-maxcontent); */
    /* margin-top: var(--dl-space-space-fiveunits); */
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-fiveunits);
    /* margin-bottom: var(--dl-space-space-fiveunits); */
  }

  .home-our-story-v3 {
    width: 75%;
    display: flex;
    /* max-width: var(--dl-size-size-maxcontent); */
    /* margin-top: var(--dl-space-space-fiveunits); */
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-fiveunits);
    /* margin-bottom: var(--dl-space-space-fiveunits); */
  }

  /* linha: 1127 */
  .home-right-section {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: row;
    justify-content: space-between;
  }

  /* linha: 1149 */
  .home-card {
    /* width: 48%; */
    display: flex;
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
    flex-direction: column;
    justify-content: center;
  }

  .home-card2 {
    /* width: 48%; */
    display: flex;
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    flex-direction: row;
    justify-content: center;
    padding-bottom: var(--dl-space-space-fourunits);
  }

  /* linha: 1187 */
  .home-content-container2 {
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: center;
  }

  /* linha: 1207 */
  .home-text24 {
    text-align: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }

  .nav-btn {
    color: var(--dl-color-seguranca);
    border-radius: var(--dl-radius-radius-radius8);
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-unit);
    /* background-color: var(--dl-color-gray-black); */
    background-color: var(--dl-color-responsabilidade);
    /* display: right; */
    
    /* border-color: var(--dl-color-gray-black); */
    /* border-width: 1px; */

    cursor: pointer;

    font-size: 18px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
  }

  .container-card-especialidades {
    /* width: 48%; */
    /* height: 100%; */
    display: flex;
    align-items: center;
    /* border-color: var(--dl-color-gray-900); */
    /* border-color: var(--dl-color-gray-1100); */
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    border-color: var(--dl-color-responsabilidade);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: space-between;
    padding: 18px;
    background-color: var(--dl-color-seguranca);
  }
  
  .container-card-especialidades2 {
    /* width: 48%; */
    /* height: 100%; */
    display: flex;
    align-items: center;
    /* border-color: var(--dl-color-gray-900); */
    /* border-color: var(--dl-color-gray-1100); */
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    border-color: var(--dl-color-responsabilidade);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: center;
    padding: 18px;
    background-color: var(--dl-color-seguranca);
    margin-bottom: var(--dl-space-space-fourunits);
  }

  .text-light-small-extra-v13 {
    color: var(--dl-color-seriedade);
    font-weight: 700;
  }

  .text-light-small-bg-extra-v13 {
    color: var(--dl-color-seriedade);
    font-weight: 999;
    background-color: var(--dl-color-responsabilidade);
    font-size: 26px;
  }

  .text-dark-small-extra-v13 {
    color: var(--dl-color-responsabilidade);
    font-weight: 999;
  }

  .text-dark-small-bg-extra-v13 {
    color: var(--dl-color-responsabilidade);
    font-weight: 999;
    background-color: var(--dl-color-seriedade);
  }

  @media (max-width: 1200px) {
    .home-our-story-v3 {
      flex-direction: column;
    }

    .home-card2 {
      flex-direction: column;
      width: 95%;
    }

    .home-card {
      width: 95%;
    }
  }

  @media (max-width: 760px) {
  }

</style>
<template>
    <div id="my_acting" class="home-our-story container-bg-light">
      <div class="home-hero-text3">
        <h2 class="home-pre-heading-v2 text-light-small-extra-v91">
          SAIBA MAIS SOBRE A MINHA ATUAÇÃO
        </h2>
        <h2 class="home-heading-v2 Section-Heading-v2 text-dark-small-extra-v9">
          Explore mais conteúdos da área
        </h2>
      </div>
      <div class="home-cards-container3">
        <!-- <CarouselMyActing /> -->
        <div class="hospital_services_cards_containers">
          <div class="home-service-card">
            <h3 class="home-text10 Card-Heading"></h3>
            <div class="container-cards-section-especialidades">
              <div class="container-group-card-especialidades2">
                <div class="container-card-especialidades-left">
                  <img
                    alt="Morpheus"
                    src="images/Mastopexia-com-Proteses-de-Silicone-526x226px.jpg"
                    class="img-especialidades"
                    height="226"
                    width="526"
                  />
                </div>
                <div class="container-card-especialidades">
                  <img
                    alt="Morpheus"
                    src="images/Lipoabdominoplastia-LMD - -Abdominoplastia-526x226px.jpg"
                    class="img-especialidades"
                    height="226"
                    width="526"
                  />
                </div>
                <div class="container-card-especialidades-right">
                  <img
                    alt="Morpheus"
                    src="images/lipoenxertia_glutea.jpg"
                    class="img-especialidades"
                    height="226"
                    width="526"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="nav-btn">
            <a href="https://api.whatsapp.com/send/?phone=13981911233&amp;text=Ol%C3%A1%2C+gostaria+de+marcar+uma+consulta.">
              AGENDAR CONSULTA
            </a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import CarouselMyActing from './CarouselMyActing.vue';

  export default {
      name: "MyActing",
      components: {
        CarouselMyActing
      },
      data() {
        return {
          rawvpwc: ' ',
          rawm3gm: ' ',
          rawyzqr: ' ',
        }
      },
  }
</script>

<style scoped>

.hospital_services_cards_containers {
    width: 100%;
    display: flex;
    margin-top: 0;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--dl-color-gray-1100);
  }

  .home-service-card {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: center;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-maturidade);
  }

  .container-cards-section-especialidades {
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .container-group-card-especialidades2 {
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    align-self: stretch;
    /* align-items: flex-start; */
    border-radius: var(--dl-radius-radius-radius4);
    border-color: var(--dl-color-responsabilidade);
    flex-direction: row;
    justify-content: space-between;
    padding: 0 32px;
    
  }

  .container-card-especialidades {
    width: 48%;
    height: 100%;
    display: flex;
    align-items: center;
    /* border-color: var(--dl-color-gray-900); */
    /* border-color: var(--dl-color-gray-1100); */
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    border-color: var(--dl-color-responsabilidade);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: space-between;
    padding: 18px;
    background-color: var(--dl-color-seguranca);
  }

  .container-card-especialidades-left {
    width: 48%;
    height: 100%;
    display: flex;
    align-items: center;
    /* border-color: var(--dl-color-gray-900); */
    /* border-color: var(--dl-color-gray-1100); */
    border-color: var(--dl-color-responsabilidade);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: center;
    margin-right: 2%;
    padding: 18px;
    background-color: var(--dl-color-seguranca);
  }

  .container-card-especialidades-left2 {
    width: 48%;
    height: 100%;
    display: flex;
    align-items: center;
    /* border-color: var(--dl-color-gray-900); */
    /* border-color: var(--dl-color-gray-1100); */
    border-color: var(--dl-color-responsabilidade);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: center;
    margin-right: 2%;
    padding: 18px;
    background-color: var(--dl-color-seguranca);
  }

  .container-card-especialidades-right {
    width: 48%;
    height: 100%;
    display: flex;
    align-items: center;
    /* border-color: var(--dl-color-gray-900); */
    /* border-color: var(--dl-color-gray-1100); */
    border-color: var(--dl-color-responsabilidade);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: column;
    justify-content: space-between;
    margin-left: 2%;
    padding: 18px;
    background-color: var(--dl-color-seguranca);
  }

  .container-especialidades-content-interno {
    display: flex;
    /* align-items: flex-start; */
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }

  .especialidades-text-small {
    text-align: left;
    margin-bottom: var(--dl-space-space-doubleunit);

    /* text-align: justify; */
    /* text-justify: inter-word; */
  }

  .img-especialidades {
    width: 100%;
    height: auto;
    border-radius: var(--dl-radius-radius-radius4);
  }
  /* linha: 183 */
  .Section-Heading-v2 {
    font-size: 48px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1.22;
    text-transform: none;
    text-decoration: none;
  }

  /* linha: 269 */
  .home-hero-text3 {
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  /* linha: 283 */
  .home-heading-v2
  .home-pre-heading-v2 {
    width: 50%;
    text-align: center;
    margin-bottom: var(--dl-space-space-fiveunits);
  }

  /* linha: 384 */
  .container-bg-light {
    background-color:var(--dl-color-gray-1100);
    background-size: cover;
  }

  /* linha: 943 */
  .home-our-story {
    width: 100%;
    display: flex;
    /* max-width: var(--dl-size-size-maxcontent); */
    /* margin-top: var(--dl-space-space-fiveunits); */
    /* margin-bottom: var(--dl-space-space-fiveunits); */
    align-items: center;
    flex-direction: column;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-fiveunits);
  }

  /* linha: 1023 */
  .home-video-container {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--dl-color-gray-900);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  /* linha: 1044 */
  .home-play-container {
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    right: auto;
    width: 50px;
    bottom: auto;
    height: 50px;
    display: flex;
    position: absolute;
    align-items: center;
    border-color: var(--dl-color-gray-500);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-round);
    flex-direction: column;
    justify-content: center;
  }

  /* linha: 1060 */
  .home-icon32 {
    fill: var(--dl-color-gray-500);
    width: 48px;
    height: 48px;
  }

  /* linha: 1698 */
  .home-left-section {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }

  /* linha: 1784 */
  .home-cards-container3 {
    width: 73%;
    align-items: center;
    flex-direction: column;
    background-color: var(--dl-color-gray-1100);
  }

  /* linha: 1867 */
  .home-video {
    height: 200px;
  }

  .container-cards-footer-especialidades {
    width: 100%;
    /* height: 100%; */
    display: flex;
    margin-top: var(--dl-space-space-doubleunit);
    align-items: center;
    justify-content: center;
    /* flex-direction: row; */
  }

  .button-especialidades {
    color: var(--dl-color-gray-black);
    display: inline-block;
    padding: 0.2rem 0.3rem;
    font-family: Raleway;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
  }

  .btn-cta-especialidades {
    color: var(--dl-color-gray-white);
    text-align: center;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-doubleunit);
    border-radius: 30px;
    padding-right: var(--dl-space-space-doubleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
    background-color: var(--dl-color-gray-black);
    width: 50%;
  } 
  

  .container-button-style-especialidades {
    font-size: 18px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
  } 

  .text-light-small-extra-v9 {
    color: var(--dl-color-seriedade);
    font-weight: 700;
  }

  .text-light-small-extra-v91 {
    color: var(--dl-color-leveza);
    font-weight: 700;
  }

  .text-dark-small-extra-v9 {
    color: var(--dl-color-responsabilidade);
    font-weight: 999;
  }

  .nav-btn {
    /* color: var(--dl-color-gray-white); */
    color: var(--dl-color-seguranca);
    border-radius: var(--dl-radius-radius-radius8);
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-unit);
    /* background-color: var(--dl-color-gray-black); */
    background-color: var(--dl-color-responsabilidade);
    /* display: right; */
    
    /* border-color: var(--dl-color-gray-black); */
    /* border-width: 1px; */

    cursor: pointer;

    font-size: 18px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
  }

</style>
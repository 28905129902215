<template>
  <div class="home-main-bg">
    <Navbar @scrollToSection="scrollToSection" :navLinks="navLinks" />
    <Inicio />
    <Especialidades />
    <Exibition />
    <!-- <Services /> -->
    <!-- para amostragem ao cliente, o hospital service visualization esta atuando como Services -->
    <HospitalServicesVisualization />
    <HospitalServices />
    <FAQ />
    <Sobre />
    <Depoimentos />
    <Counter />
    <MyActing />
    <!-- <Contato /> -->
    <ContatoVisualization />
    <SectionSeparator />
    <Footer />
  </div>
</template>

<script>
  import Contato from './components/Contato.vue'
  import ContatoVisualization from './components/ContatoVisualization.vue'
  import Counter from './components/Counter.vue'
  import Depoimentos from './components/Depoimentos.vue'
  import Especialidades from './components/Especialidades.vue'
  import Exibition from './components/Exibition.vue'
  import FAQ from './components/FAQ.vue'
  import Footer from './components/Footer.vue'
  import HospitalServices from './components/HospitalServices.vue'
  import HospitalServicesVisualization from './components/HospitalServicesVisualization.vue'
  import Inicio from './components/Inicio.vue'
  import MyActing from './components/MyActing.vue'
  import SectionSeparator from './components/SectionSeparator.vue'
  import Services from './components/Services.vue'
  import Sobre from './components/Sobre.vue'
  import Navbar from './components/Navbar.vue';

  export default {
    name: 'Home',
    components: {
      Contato,
      ContatoVisualization,
      Counter,
      Depoimentos,
      Especialidades,
      Exibition,
      FAQ,
      Footer,
      HospitalServices,
      HospitalServicesVisualization,
      Inicio,
      MyActing,
      SectionSeparator,
      Services,
      Sobre,
      Navbar,
    },
    data() {
      return {
        rawvpwc: ' ',
        rawm3gm: ' ',
        rawyzqr: ' ',
        navLinks: [
          { id: 'inicio', text: 'Inicio' },
          { id: 'especialidades', text: 'Especialidades' },
          { id: 'faq', text: 'FAQ' },
          { id: 'sobre', text: 'Sobre' },
          { id: 'depoimentos', text: 'Depoimentos' },
          { id: 'contato', text: 'Contato' },
        ],
      }
    },
    methods: {
    scrollToSection(id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      },
    },
    metaInfo: {
      title: 'Dr. Antônio Teixeira',
      meta: [
        {
          property: 'og:title',
          content: 'Landing Page',
        },
      ],
    },
  }
</script>

<style scoped>
  .home-main-bg {
    background-color:var(--dl-color-responsabilidade);
    background-size: cover;
  }
</style>

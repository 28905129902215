<template>
  <div id="faq" class="home-services container-bg-light">
    <div class="home-heading-inicial-container">
      <h2 class="home-pre-heading-v2 text-dark-small-extra-v8">Perguntas Frequentes</h2>
    </div>
    <div id="faq" class="home-services2 container-bg-light">
      <div class="home-heading-container2">
        <h2 class="home-heading-v2 Section-Heading-v2">FAQ</h2>
      </div>
      <!-- <div class="home-cards-container1-v2">
        <div class="home-service-card-v2">
          <h3 class="home-text10 Card-Heading">Qual é o período médio de recuperação após uma cirurgia plástica?</h3>
          <span class="home-text11 Card-Text">
            O período de recuperação após uma cirurgia plástica varia conforme os procedimentos e o
            organismo da paciente. Geralmente, pode levar de algumas semanas ha vários meses. 
            Porém para que os resultados finais sejam visíveis, levará em média de 9 a 12 meses ou mais.
            <span v-html="rawvpwc"></span>
          </span>
          <span class="Anchor">Learn more</span>
        </div>
        <div class="home-service-card-v2">
          <h3 class="home-text12 Card-Heading">É comum ter inchaço após a realização de um procedimento de cirurgia plástica?</h3>
          <span class="home-text13 Card-Text">
            Sim, é comum ter inchaço após uma cirurgia plástica. O inchaço é uma resposta natural do corpo à
            intervenção cirúrgica e geralmente diminui ao longo do processo de recuperação, podendo levar 
            semanas ou meses para desaparecer completamente, dependendo do procedimento.
            <span v-html="rawm3gm"></span>
          </span>
          <span class="Anchor">Learn more</span>
        </div>
        <div class="home-service-card-v2">
          <h3 class="home-text14 Card-Heading">Em quais casos é indicada a utilização de cinta ou sutiã pós-operatório? Qual é a sua importância?</h3>
          <span class="home-text15 Card-Text">
            A cinta pós-operatória é recomendada em procedimentos como lipoaspiração e abdominoplastia. 
            Ela ajuda a reduzir o inchaço, promove a cicatrização adequada, e proporciona suporte, contribuindo
            para resultados mais satisfatórios e uma recuperação mais confortável. O sutiã pós-operatório 
            é indicado nas cirurgias mamárias como proteses, mamoplastia redutora ou mastopexia. Ele tem 
            a função de sustentar as mamas, moldando as até que ocorra a completa cicatrização.
            <span v-html="rawyzqr"></span>
          </span>
          <span class="Anchor">Learn more</span>
        </div>
        <div class="home-service-card-v2">
          <h3 class="home-text14 Card-Heading">Quais são as perguntas essenciais a serem feitas durante a primeira consulta, sobre o procedimento indicado para o meu caso?</h3>
          <span class="home-text15 Card-Text">
            Durante a primeira consulta, é fundamental discutir suas expectativas, e quais os possíveis
            resultados alcançáveis. Analisar sua  anatômia individual, seus antecedentes médicos, cirurgias
            anteriores e medicamentos que está fazendo o uso, com base nessas informações, poderemos
            indicar o procedimento mais adequado para você.
            <span v-html="rawyzqr"></span>
          </span>
          <span class="Anchor">Learn more</span>
        </div>
        <div class="home-service-card-v2">
          <h3 class="home-text14 Card-Heading">Quais são os cuidados necessários durante o período de recuperação após a cirurgia plástica?</h3>
          <span class="home-text15 Card-Text">
            Durante a recuperação pós-cirurgia plástica, é vital seguir as nossas orientações, incluindo 
            repouso, uso de medicamentos prescritos, evitar esforços e manter uma dieta saudável. O 
            acompanhamento com nossa equipe multidisciplinar, também fará você ter uma recuperação bem-sucedida.
            
            <b>
              Atualmente também temos indicado sessões de câmara hiperbárica (oxigênio terápia) para 
              melhorar a cicatrização e a recuperação dos tecidos.
            </b>
            <span v-html="rawyzqr"></span>
          </span>
          <span class="Anchor">Learn more</span>
        </div>
        <div class="home-service-card-v2">
          <h3 class="home-text14 Card-Heading">Quanto tempo leva para ver os resultados finais do procedimento de cirurgia plástica?</h3>
          <span class="home-text15 Card-Text">
            O tempo para ver os resultados finais varia de acordo com o tipo de cirurgia plástica, com o
            organismo da paciente, mas geralmente leva em média de 9 a 12 meses para que os resultados
            se estabilizem completamente. O acompanhamento médico é importante para monitorar o progresso.
            <span v-html="rawyzqr"></span>
          </span>
          <span class="Anchor">Learn more</span>
        </div>
        <div class="home-service-card-v2">
          <h3 class="home-text14 Card-Heading">Existem riscos associados ao inchaço prolongado após a cirurgia plástica? Como eles são tratados?</h3>
          <span class="home-text15 Card-Text">
            O inchaço prolongado pós-cirurgia plástica pode indicar intercorrências como acúmulo de
            líquido (seroma ou hematoma). Caso acontecer você deverá comunicar imediatamente a nossa
            equipe. O tratamento pode envolver drenagem, medicamentos ou procedimentos específicos 
            para resolver a causa subjacente.
            <span v-html="rawyzqr"></span>
          </span>
          <span class="Anchor">Learn more</span>
        </div>
        <div class="home-service-card-v2">
          <h3 class="home-text14 Card-Heading">Qual a importância da consulta pré-operatória com um cirurgião plástico para esclarecer dúvidas sobre o procedimento desejado?</h3>
          <span class="home-text15 Card-Text">
            A primeira consulta é o momento mais importante para realmente avaliar O seu caso, decidirmos qual
            o melhor tratamento, esclarecer dúvidas, discutir riscos, avaliar a expectativa dos resultados
            desejáveis X reais (atingíveis), criando um plano personalizado e seguro.
            <span v-html="rawyzqr"></span>
          </span>
          <span class="Anchor">Learn more</span>
        </div>
      </div> -->
      <FAQToogle />
    </div>
  </div>
</template>

<script>
  import FAQToogle from './FAQToogle.vue';

  export default {
      name: "FAQ",
      components: {
        FAQToogle
      },
      data() {
        return {
          rawvpwc: ' ',
          rawm3gm: ' ',
          rawyzqr: ' ',
        }
      },
  }
</script>

<style scoped>
  /* linha: 183 */
  .Section-Heading-v2 {
    font-size: 48px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1.22;
    text-transform: none;
    text-decoration: none;
  }

  /* linha: 283 */
  .home-heading-v2
  .home-pre-heading-v2 {
    width: 100%;
    text-align: center;
    margin-bottom: var(--dl-space-space-fiveunits);
  }

  /* linha: 384 */
  .container-bg-light {
    background-color:var(--dl-color-gray-1100);
    background-size: cover;
  }

  /* linha: 645 */
  .home-services {
    width: 100%;
    display: flex;
    /* max-width: var(--dl-size-size-maxcontent); */
    /* margin-top: var(--dl-space-space-fiveunits); */
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    /* margin-bottom: var(--dl-space-space-fiveunits); */
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-doubleunit);
  }

  .home-services2 {
    width: 70%;
    display: flex;
    /* max-width: var(--dl-size-size-maxcontent); */
    /* margin-top: var(--dl-space-space-fiveunits); */
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    /* margin-bottom: var(--dl-space-space-fiveunits); */
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
  }

  .home-heading-container2 {
    width: 25%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
    /* color: #FFF; */
    color: var(--dl-color-responsabilidade);
    /* background-image: linear-gradient(to left, transparent 0%, #fff 50%), image-set("./images/ANTONIO PLASTICA-231.jpg"); */
  }

  .home-heading-inicial-container {
    width: 70%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
    color: #FFF;
    justify-content: center;
  }

  .home-heading-v2 {
    font-size: 150px;
    background-image: linear-gradient(to top, transparent 0%, var(--dl-color-responsabilidade) 90%);
    background-clip: text;
    color: transparent;
    
  }

  .text-dark-small-extra-v8 {
    color: var(--dl-color-responsabilidade);
    font-weight: 999;
  }

  h2 {
    font-size: 48px;
  }

  @media (max-width: 1200px) {
    .home-services2 {
      width: 100%;
      flex-direction: column;
    }

    .home-heading-inicial-container {
      width: 100%;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    .home-pre-heading-v2 {
      width: 80%;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 760px) {
  }

</style>